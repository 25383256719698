export default {
    props: {
        value: Boolean,
        inputclass: {
            type: String,
            default: ''
        },
    },
    name: 'InputRadio',
    data() {
        return {
        };
    },
};