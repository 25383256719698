import Vue from 'vue';
import Paging from '../components/shared/common/paging.vue';
import TitleHeader from '../components/shared/common/titleheader.vue';
import FormGroup from '../components/shared/common/formgroup.vue';
import FormGroupCheck from '../components/shared/common/formgroupcheck.vue';
import InputText from '../components/shared/common/inputs/inputtext.vue';
import InputTextArea from '../components/shared/common/inputs/inputtextarea.vue';
import InputToggle from '../components/shared/common/inputs/inputtoggle.vue';
import InputCheck from '../components/shared/common/inputs/inputcheck.vue';
import InputFile from '../components/shared/common/inputs/inputfile.vue';
import InputMoney from '../components/shared/common/inputs/inputmoney.vue';
import ReadonlyText from '../components/shared/common/readonly/readonlytext.vue';
import Search from '../components/shared/common/inputs/search.vue';
import SelectUser from '../components/shared/common/inputs/selectuser.vue';
import SelectCountry from '../components/shared/common/inputs/selectcountry.vue';
import SelectCurrency from '../components/shared/common/inputs/selectcurrency.vue';
import SelectMonth from '../components/shared/common/inputs/selectmonth.vue';
import SelectYear from '../components/shared/common/inputs/selectyear.vue';
import SelectLanguage from '../components/shared/common/inputs/selectlanguage.vue';
import DatePicker from '../components/shared/common/inputs/datepicker.vue';
import Spinner from '../components/shared/common/spinner.vue';
import wysiwyg from 'vue-wysiwyg';
import VueAutonumeric from 'vue-autonumeric';
import Flag from '../components/shared/common/flag.vue';
import ButtonDelete from '../components/shared/common/buttons/buttondelete.vue';
import ButtonSave from '../components/shared/common/buttons/buttonsave.vue';
import ControlPanel from '../components/shared/common/controlpanel.vue';
import SelectTimezone from '../components/shared/common/inputs/selecttimezone.vue';
import SelectCompanyType from '../components/shared/common/inputs/selectcompanytype.vue';
import SelectAgent from '../components/shared/common/inputs/selectagent.vue';
import InputRadio from '../components/shared/common/inputs/inputradio.vue';
import Select3pSupplier from '../components/shared/common/inputs/select3psupplier.vue';
import SelectCompanyGroup from '../components/shared/common/inputs/selectcompanygroup.vue';
import SelectEnumType from '../components/shared/common/inputs/selectenumtype.vue';
import SelectTaxDescription from '../components/shared/common/inputs/selecttaxdescription.vue';
import SelectVatRegime from '../components/shared/common/inputs/selectvatregime.vue';
import SelectTaxDepartment from '../components/shared/common/inputs/selecttaxdepartment.vue';
import StickyMenu from '../components/shared/common/stickymenu.vue';
import BreadCrumbs from '../components/shared/common/breadcrumbs.vue';
import ProgressDialog from '../components/shared/common/progressdialog.vue';
import SelectOptions from '../components/shared/common/inputs/selectoptions.vue';
import { default as Vuedals, Component as Vuedal } from 'vuedals';
import select2Extended from '../components/shared/common/inputs/select2.vue';
import SelectWorktypes from '../components/shared/common/inputs/selectworktype.vue';
import Chart from '../components/shared/common/graph/Chart.vue';

// Global components
Vue.component('select2', select2Extended);
Vue.component('paging', Paging);
Vue.component('titleheader', TitleHeader);
Vue.component('formgroup', FormGroup);
Vue.component('formgroupcheck', FormGroupCheck);
Vue.component('inputtext', InputText);
Vue.component('inputtextarea', InputTextArea);
Vue.component('inputtoggle', InputToggle);
Vue.component('inputcheck', InputCheck);
Vue.component('inputfile', InputFile);
Vue.component('inputmoney', InputMoney);
Vue.component('readonlytext', ReadonlyText);
Vue.component('search', Search);
Vue.component('selectuser', SelectUser);
Vue.component('selectcountry', SelectCountry);
Vue.component('selectcurrency', SelectCurrency);
Vue.component('selectlanguage', SelectLanguage);
Vue.component('datepicker', DatePicker);
Vue.component('spinner', Spinner);
Vue.use(wysiwyg, {forcePlainTextOnPaste: true});
Vue.component('vue-autonumeric', VueAutonumeric);
Vue.component('flag', Flag);
Vue.component('buttondelete', ButtonDelete);
Vue.component('buttonsave', ButtonSave);
Vue.component('controlpanel', ControlPanel);
Vue.component('selectmonth', SelectMonth);
Vue.component('selectyear', SelectYear);
Vue.component('selecttimezone', SelectTimezone);
Vue.component('selectcompanytype', SelectCompanyType);
Vue.component('selectagent', SelectAgent);
Vue.component('inputradio', InputRadio);
Vue.component('select3psupplier', Select3pSupplier);
Vue.component('selectenumtype', SelectEnumType);
Vue.component('selectcompanygroup', SelectCompanyGroup);
Vue.component('stickymenu', StickyMenu);
Vue.component('breadcrumbs', BreadCrumbs);
Vue.component('selecttaxdescription', SelectTaxDescription);
Vue.component('selectvatregime', SelectVatRegime);
Vue.component('selecttaxdepartment', SelectTaxDepartment);
Vue.use(Vuedals);
Vue.component('vuedal', Vuedal);
Vue.component('progressdialog', ProgressDialog);
Vue.component('selectoptions', SelectOptions);
Vue.component('selectworktypes', SelectWorktypes);
Vue.component('chart', Chart);
import VuejsDialog from 'vuejs-dialog';

Vue.use(VuejsDialog,{ 
    html: true,
    loader: false,
    okText: 'Ok',
    cancelText: 'Annuleren',
    animation: '',
});
