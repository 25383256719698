import Select2 from 'v-select2-component';

export default {
    components: {
        'select2': Select2
    },
    name: 'SelectMonth',
    props: {
        value: Number,
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type: String,
            default: null
        },
        isnumeric: {
            type: Boolean,
            default: false
        },
        includemonths: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            months: []
        };
    },
    computed: {
        options() {
            if (this.isnumeric) {
                return this.months.map((month, index) => {
                    return { id: month, text: month };
                });
            }
            else {
                return this.months.map((month, index) => {
                    return { id: index + 1, text: month };
                });
            }
        },
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            if (this.isnumeric)
                this.months = this.includemonths;
            else
                this.months = [
                    this.$t('periods.january'),
                    this.$t('periods.february'),
                    this.$t('periods.march'),
                    this.$t('periods.april'),
                    this.$t('periods.may'),
                    this.$t('periods.june'),
                    this.$t('periods.july'),
                    this.$t('periods.august'),
                    this.$t('periods.september'),
                    this.$t('periods.october'),
                    this.$t('periods.november'),
                    this.$t('periods.december')];
        }
    },
    watch: {
        includemonths() {
            this.fetch();
        }
    }
};