var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pd-10 bd bg-white mg-b-7 sticky-menu tx-12",
      class: { "form-inline": _vm.inline }
    },
    [
      _vm.title
        ? _c(
            "span",
            { staticClass: "nav-link tx-gray-900 tx-uppercase tx-bold " },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }