import { COMPANYMEMOLIST_SEARCH, COMPANYMEMOLIST_PAGE } from '../actions/companymemolistactions';

const state = { search: '', page: 0 };

const getters = {
    page: state => state.page,
    search: state => state.search
};

const actions = {
    [COMPANYMEMOLIST_SEARCH]: ({ commit }, search) => {
        commit(COMPANYMEMOLIST_SEARCH, search);
    },
    [COMPANYMEMOLIST_PAGE]: ({ commit }, page) => {
        commit(COMPANYMEMOLIST_PAGE, page);
    }
};

const mutations = {
    [COMPANYMEMOLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [COMPANYMEMOLIST_PAGE]: (state, page) => {
        state.page = page;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};