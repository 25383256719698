import axios from 'axios';
import Select2 from 'v-select2-component';

export default {
    components: {
        'select2': Select2
    },
    name: 'SelectTaxDepartment',
    props: {
        value: Number,
        countryId: Number,
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type:String,
            default: null
        }
    },
    data() {
        return {
            list:[]
        };
    },
    computed: {
        options() {
            return this.list.map(x => {
                return {id:x.id, text: x.name};
            });
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            axios.get(APIURL + '/compliance/settings/country/'+this.countryId+'/taxdepartments')
                .then(resp => this.list=resp.data);
        },
    }
};