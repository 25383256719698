import axios from 'axios';
import Select2 from 'v-select2-component';

export default {
    components: {
        'select2': Select2
    },
    name: 'SelectUser',
    props: {
        value: [Number,String],
        nametype: {
            type: String,
            default: 'fullname'
        },
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type:String,
            default: null
        },
        istimeregistration  : {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            users:[]
        };
    },
    computed: {
        options() {
            const vm = this;
            return this.users.map(x => {
                return {id:x.id, text: vm.getName(x)};
            });
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            axios.get(APIURL + '/user/list?isTimeregistration=' + this.istimeregistration)
                .then(resp => this.users=resp.data);
        },
        getName(user) {
            if(this.nametype === 'initials')
                return user.initials;
            else
                return user.name;
        }
    }
};