import Select2 from 'v-select2-component';

export default {
    components: {
        'select2': Select2
    },
    name: 'SelectCompanyType',
    props: {
        value: Number,
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type:String,
            default: null
        }
    },
    data() {
        return {
            types: [
                this.$tc('companyType.unknown'),
                this.$tc('companyType.customer'),
                this.$tc('companyType.agent'),
                this.$tc('companyType.thirdParty'),
                this.$tc('companyType.employee'),
                this.$tc('companyType.person'),
            ]
        };
    },
    computed: {
        options() {
            return this.types.map((type, index) => {
                return {id:index, text: type};
            });
        },
    },
};