export default {
    props: {
        value: String,
        inputclass: {
            type: String,
            default: ''
        },
        readonly: { 
            type: Boolean,
            default: false
        }
    },
    name: 'InputTextarea'
};