var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "btn btn-success btn-icon", attrs: { type: "submit" } },
      [_c("div", [_c("i", { staticClass: "fas fa-save" })])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }