import { AUTH_LOGOUT } from '../../../store/actions/authactions';
import { mapActions, mapGetters } from 'vuex';
import crmMenu from '../../crm/menu';
import complainceMenu from '../../compliance/menu';
import managementMenu from '../../management/menu';
import sharedMenu from '../menu';
import axios from 'axios';
import toastr from 'toastr';


export default {
    data() {
        return {
            menudata: {
                settings: [],
                companies: [],
                reports: []
            },
            bedgecount: 0,
            initialized: false
        };
    },
    components: {
    },
    name: 'NavMenu',
    computed: {
        ...mapGetters('auth', [
            'isAdministrator',
            'currentmodule',
            'isTimeRegistration',
            'isCRMAdministrator'
        ]),


    },
    mounted() {
        this.buildMenu();
        this.$eventbus.$on('bedgecount-after-save', this.getBedgeCount);
        this.getBedgeCount();
    },
    watch: {
        currentmodule() {
            this.buildMenu();
        },

    },
    methods: {
        getBedgeCount() {

            let url = APIURL + '/shared/companies/declarationproposal/getbedge/';
            axios.get(url)
                .then(resp => {
                    this.bedgecount = resp.data;
                });
        },
        buildMenu() {
            this.menudata = {
                settings: [],
                companies: [],
                reports: [],
                timeregistration: []
            };
            if (this.currentmodule === 'CRM') {
                this.menudata.settings = crmMenu.settings.slice();
                this.menudata.companies = crmMenu.companies.slice();
                this.menudata.reports = crmMenu.reports.slice();
                //this.$router.push({ name: 'crmcompanies' });
            }
            else if (this.currentmodule === 'COMPLIANCE') {
                this.menudata.settings = complainceMenu.settings.slice();
                this.menudata.companies = complainceMenu.companies.slice();
                this.menudata.reports = complainceMenu.reports.slice();
                //this.$router.push({ name: 'companies' });
            }
            else if (this.currentmodule === 'MANAGEMENT') {
                this.menudata.settings = managementMenu.settings.slice();
                this.menudata.companies = managementMenu.companies.slice();
                this.menudata.reports = managementMenu.reports.slice();
                //this.$router.push({ name: 'companies' });
            }
            for (var i = 0; i <= sharedMenu.settings.length - 1; i++) {
                this.menudata.settings.push(sharedMenu.settings[i]);
            }
            let role = this.isAdministrator ? 'administrator' : 'customer';
            this.menudata.timeregistration = sharedMenu.timeregistration.slice().filter(s => role=='administrator' || s.role == role);       
            if (this.isAdministrator && this.isTimeRegistration == false)
                this.menudata.timeregistration = this.menudata.timeregistration.filter(el => !el.isTimeRegistration);

            if (this.isAdministrator) {
                this.menudata.invoicing = sharedMenu.invoicing.slice();
            }
            else
                this.menudata.invoicing = [];
            this.initialized = true;
        },
        subIsActive(input) {

            const paths = Array.isArray(input) ? input : [input];

            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0; // current path starts with this path string
            });
        },
        logout: function () {
            this.doLogout()
                .then(() => {
                    this.$router.push({ name: 'login' });
                });
        },
        ...mapActions('auth', {
            doLogout: AUTH_LOGOUT
        }),
    }
};