import listmodule from './list.js';
import {COMPANYPURCHASELIST_STARTDATE,COMPANYPURCHASELIST_ENDDATE,COMPANYPURCHASELIST_SELLERCOUNTRYID} from '../actions/companypurchaselistactions.js';
import moment from 'moment';
import 'moment-timezone';
const state = {
    startDate: moment().tz('UTC').startOf('year').toDate(),
    endDate: moment().tz('UTC').endOf('year').toDate(),
    sellerCountryId: null,
    ...listmodule.state
};

const getters = {
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    sellerCountryId: state => state.sellerCountryId,
    ...listmodule.getters
};

const actions = {
    [COMPANYPURCHASELIST_STARTDATE]: ({ commit }, startDate) => {
        commit(COMPANYPURCHASELIST_STARTDATE, startDate);
    },
    [COMPANYPURCHASELIST_ENDDATE]: ({ commit }, endDate) => {
        commit(COMPANYPURCHASELIST_ENDDATE, endDate);
    },
    [COMPANYPURCHASELIST_SELLERCOUNTRYID]: ({ commit }, sellerCountryId) => {
        commit(COMPANYPURCHASELIST_SELLERCOUNTRYID, sellerCountryId);
    },
    ...listmodule.actions
};

const mutations = {
    [COMPANYPURCHASELIST_STARTDATE]: (state, startDate) => {
        state.page = 0;
        state.startDate = startDate;
    },
    [COMPANYPURCHASELIST_ENDDATE]: (state, endDate) => {
        state.page = 0;
        state.endDate = endDate;
    },
    [COMPANYPURCHASELIST_SELLERCOUNTRYID]: (state, sellerCountryId) => {
        state.page = 0;
        state.sellerCountryId = sellerCountryId;
    },
    ...listmodule.mutations
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};