import * as actionList from '../actions/reportundeclaredhoursactions';
import moment from 'moment';

const state = {
    search: '',
    page: 0,
    year: moment().year(),
    userid: 0,
    worktype: 0
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    year: state => state.year,
    userid: state => state.userid,
    worktype: state => state.worktype,
};

const actions = {
    [actionList.REPORTUNDECLAREDHOURS_SEARCH]: ({ commit }, search) => {
        commit(actionList.REPORTUNDECLAREDHOURS_SEARCH, search);
    },
    [actionList.REPORTUNDECLAREDHOURS_PAGE]: ({ commit }, page) => {
        commit(actionList.REPORTUNDECLAREDHOURS_PAGE, page);
    },
    [actionList.REPORTUNDECLAREDHOURS_YEAR]: ({ commit }, year) => {
        commit(actionList.REPORTUNDECLAREDHOURS_YEAR, year);
    },
    [actionList.REPORTUNDECLAREDHOURS_WORKTYPE]: ({ commit }, worktype) => {
        commit(actionList.REPORTUNDECLAREDHOURS_WORKTYPE, worktype);
    },
    [actionList.REPORTUNDECLAREDHOURS_USERID]: ({ commit }, userid) => {
        commit(actionList.REPORTUNDECLAREDHOURS_USERID, userid);
    },
};

const mutations = {
    [actionList.REPORTUNDECLAREDHOURS_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionList.REPORTUNDECLAREDHOURS_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionList.REPORTUNDECLAREDHOURS_YEAR]: (state, year) => {
        state.page = 0;
        state.year = year;
    },
    [actionList.REPORTUNDECLAREDHOURS_WORKTYPE]: (state, worktype) => {
        state.page = 0;
        state.worktype = worktype;
    },
    [actionList.REPORTUNDECLAREDHOURS_USERID]: (state, userid) => {
        state.page = 0;
        state.userid = userid;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};