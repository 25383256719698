import 'bootstrap';
import './scripts/bracket';
import 'leaflet';
import axios from 'axios';
import Vue from 'vue';
import store from './store';
import './css/app.scss';
import toastr from 'toastr';

import * as Sentry from '@sentry/browser';
//Sentry.init({
//    dsn: 'https://091aef91a693446589f3f103a45be079@sentry.io/1267576',
//    integrations: [new Sentry.Integrations.Vue({ Vue })],
//    environment: PRODUCTION ? 'Production' : 'Development',
//    release: 'vat-employee-portal@1.0.24.0'
//});

Vue.config.productionTip = false;

import i18n from './vue/i18n.js';

import nl from 'vee-validate/dist/locale/nl';
import VeeValidate, { Validator, Field } from 'vee-validate';

// Localize takes the locale.00 object as the second argument (optional) and merges it.
Validator.localize('nl', nl);
Validator.extend('required2', {
    getMessage: field => 'The ' + field + ' value is not valid.',
    validate: value => value > 0,
    field: field => field
});

const validationConfig = {
    aria: true,
    classNames: {
        valid: 'is-valid',
        invalid: 'is-invalid'
    },
    classes: true,
    delay: 0,
    dictionary: null,
    errorBagName: 'errors', // change if property conflicts
    events: 'input|blur',
    fieldsBagName: 'fields',
    i18n: i18n, // the vue-i18n plugin instance
    i18nRootKey: 'validations', // the nested key under which the validation messages will be located
    inject: true,
    locale: 'nl',
    strict: true,
    validity: false,
};

Vue.use(VeeValidate, validationConfig);

// password strength
VeeValidate.Validator.extend('verify_password', {
    getMessage: field => i18n.t('passwordRequirements'),
    validate: value => {
        var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})');
        return strongRegex.test(value);
    }
});

import './vue/globalcomponents.js';
import './vue/displayFormats.js';

const token = localStorage.getItem('user-token');
if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}
axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.defaults.headers.common['Pragma'] = 'no-cache';
import moment from 'moment';
import 'moment-timezone';
const ISO_8601 = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}(.\d{3})?)(Z)?/;
function convertDateStringsToDateObjects(obj) {
    for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (typeof obj[property] == 'object') {
                convertDateStringsToDateObjects(obj[property]);
            }
            else {
                if (ISO_8601.test(obj[property])) {
                    //obj[property] = new Date(Date.parse(obj[property]));
                    obj[property] = moment(obj[property]).tz(i18n.t('format.timezone')).toDate();
                }
            }
        }
    }
    return obj;
}

axios.interceptors.response.use(response => {
    response.data = convertDateStringsToDateObjects(response.data);
    return response;
}, error => Promise.reject(error));

import { AUTH_LOGOUT } from './store/actions/authactions';

axios.interceptors.response.use(response => response,
    error => {
        if (error.response) {
            if (error.response.status == 401) {
                store.dispatch(`auth/${AUTH_LOGOUT}`)
                    .then(() => {
                        router.push({ name: 'login' });
                    });
            }
            else if (error.response.status == 409) {
                toastr.error(i18n.t('error.deleteRecordConflict'), '');
            }
        }

        return Promise.reject(error);
    }
);

//axios.defaults.baseURL = APIURL;
Vue.prototype.$http = axios;

import App from './components/shared/app/app.vue';
import router from './vue/router';

const eventbus = new Vue();

Object.defineProperty(Vue.prototype, '$eventbus', {
    get() { return eventbus; }
});

new Vue({
    el: '#app-root',
    router,
    store,
    i18n,
    render: h => h(App)
});