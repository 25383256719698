import { USERTIMELIST_SEARCH, USERTIMELIST_PAGE, USERTIMELIST_WEEK, USERTIMELIST_YEAR } from '../actions/usertimelistactions';
import moment from 'moment';
const state = {
    search: '',
    page: 0,
    year: moment().format('YYYY'),
    week: moment(new Date(), "MMDDYYYY").isoWeek(),
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    week: state => state.week,
    year: state => state.year
};

const actions = {
    [USERTIMELIST_SEARCH]: ({ commit }, search) => {
        commit(USERTIMELIST_SEARCH, search);
    },
    [USERTIMELIST_PAGE]: ({ commit }, page) => {
        commit(USERTIMELIST_PAGE, page);
    },
    [USERTIMELIST_WEEK]: ({ commit }, week) => {
        commit(USERTIMELIST_WEEK, week);
    },
    [USERTIMELIST_YEAR]: ({ commit }, year) => {
        commit(USERTIMELIST_YEAR, year);
    },

};

const mutations = {
    [USERTIMELIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [USERTIMELIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [USERTIMELIST_WEEK]: (state, week) => {
        state.page = 0;
        state.week = week;
    },
    [USERTIMELIST_YEAR]: (state, year) => {
        state.page = 0;
        state.year = year;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};