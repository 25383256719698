import listmodule from './list.js';
import {CHECKVATLIST_INVALID} from '../actions/checkvatlistactions.js';

const state = {
    invalid: true,
    ...listmodule.state
};

const getters = {
    invalid: state => state.invalid,
    ...listmodule.getters
};

const actions = {
    [CHECKVATLIST_INVALID]: ({ commit }, invalid) => {
        commit(CHECKVATLIST_INVALID, invalid);
    },
    ...listmodule.actions
};

const mutations = {
    [CHECKVATLIST_INVALID]: (state, invalid) => {
        state.page = 0;
        state.invalid = invalid;
    },
    ...listmodule.mutations
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};