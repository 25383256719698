import { USERLIST_SEARCH, USERLIST_PAGE } from '../actions/userlistactions';

const state = { search:'', page:0 };

const getters = {
    page: state => state.page,
    search: state => state.search
};

const actions = {
    [USERLIST_SEARCH]: ({ commit }, search) => {
        commit(USERLIST_SEARCH, search);
    },
    [USERLIST_PAGE]: ({ commit }, page) => {
        commit(USERLIST_PAGE, page);
    }
};

const mutations = {
    [USERLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [USERLIST_PAGE]: (state, page) => {
        state.page = page;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};