import VueDatepickerLocal from 'vue-datepicker-local';

export default {
    components: {
        'vue-datepicker-local': VueDatepickerLocal
    },
    props: {
        value: {
            type: Date,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        typeable: {
            type: Boolean,
            default: true
        },
        inputclass: {
            type: String,
            default: ''
        },
        clearable: {
            type: Boolean,
            default: true
        },
        disabledate: {
            type: Date,
            default: null
        }
    },
    name: 'DatePicker',
    data() {
        return {
            local: {
                dow: 1, // Monday is the first day of the week
                hourTip: '', // tip of select hour
                minuteTip: '', // tip of select minute
                secondTip: '', // tip of select second
                yearSuffix: '', // format of head
                monthsHead: [
                    this.$t('periods.januaryShort'),
                    this.$t('periods.februaryShort'),
                    this.$t('periods.marchShort'),
                    this.$t('periods.aprilShort'),
                    this.$t('periods.mayShort'),
                    this.$t('periods.juneShort'),
                    this.$t('periods.julyShort'),
                    this.$t('periods.augustShort'),
                    this.$t('periods.septemberShort'),
                    this.$t('periods.octoberShort'),
                    this.$t('periods.novemberShort'),
                    this.$t('periods.decemberShort'),
                ], // months of head
                months: [
                    this.$t('periods.januaryShort'),
                    this.$t('periods.februaryShort'),
                    this.$t('periods.marchShort'),
                    this.$t('periods.aprilShort'),
                    this.$t('periods.mayShort'),
                    this.$t('periods.juneShort'),
                    this.$t('periods.julyShort'),
                    this.$t('periods.augustShort'),
                    this.$t('periods.septemberShort'),
                    this.$t('periods.octoberShort'),
                    this.$t('periods.novemberShort'),
                    this.$t('periods.decemberShort'),
                ], // months of panel
                weeks: 'Ma_Di_Wo_Do_Vr_Za_Zo'.split('_'), // weeks
                cancelTip: '', // default text for cancel button 
                submitTip: '' // default text for submit button 
            }
        };
    },
    methods: {
        inputChanged(val) {
            this.$emit('input', val);
        },
        disabledDate(val) {
            if (this.disabledate != null) {
                return val <= this.disabledate;
            }
            else
                return false;
        }
    }
};
