import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import i18n from './i18n.js';

Vue.use(VueRouter);

const lazyRoutes = {
    login: () => import(/* webpackChunkName: "login" */ '../components/shared/login/login.vue'),
    editProfile: () => import(/* webpackChunkName: "login" */ '../components/shared/login/editprofile.vue'),
    home: () => import(/* webpackChunkName: "home" */ '../components/shared/home/home.vue'),

    companyfilter: () => import(/* webpackChunkName: "home" */ '../components/shared/companies/companyfilter.vue'),

    companies: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/companies.vue'),
    companyList: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/list.vue'),
    companyEdit: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/edit.vue'),
    companyEditGeneral: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/editgeneral.vue'),
    companyEditDeclarations: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/editdeclarations.vue'),
    companyEditRefunds: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/editrefunds.vue'),
    companyEditExcises: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/editexcise.vue'),
    companyEditMemo: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/editmemo.vue'),
    companyReminderLog: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/editreminderlog.vue'),
    companyImportFile: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/importfile.vue'),
    companyTransaction: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/companytransaction.vue'),
    companyTaxData: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/taxdata.vue'),
    companyTaxOverviews: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/taxoverviews.vue'),
    companyTaxThresholds: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/taxthresholds.vue'),
    companyHangfire: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/hangfire.vue'),
    checkVat: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/checkvat.vue'),
    imports: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/imports.vue'),
    companyPurchases: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/purchases.vue'),
    companyTaxOverviewsAddOn: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/taxoverviewsaddon.vue'),
    companyOneStop: () => import(/* webpackChunkName: "company" */ '../components/compliance/companies/onestopstop.vue'),

    settingsCountries: () => import(/* webpackChunkName: "settings" */ '../components/compliance/settings/countries/countries.vue'),
    settingsCountryList: () => import(/* webpackChunkName: "settings" */ '../components/compliance/settings/countries/list.vue'),
    settingsCountryEdit: () => import(/* webpackChunkName: "settings" */ '../components/compliance/settings/countries/edit.vue'),
    settingsEmailTemplates: () => import(/* webpackChunkName: "settings" */ '../components/compliance/settings/emailtemplates/emailtemplates.vue'),
    settingsEmailTemplateEdit: () => import(/* webpackChunkName: "settings" */ '../components/compliance/settings/emailtemplates/edit.vue'),
    settingsEmailTemplateTranslations: () => import(/* webpackChunkName: "settings" */ '../components/compliance/settings/emailtemplates/translations.vue'),
    settingsCurrencies: () => import(/* webpackChunkName: "settings" */ '../components/compliance/settings/currencies/currencies.vue'),
    settingsCurrencyList: () => import(/* webpackChunkName: "settings" */ '../components/compliance/settings/currencies/list.vue'),
    settingsCurrencyEdit: () => import(/* webpackChunkName: "settings" */ '../components/compliance/settings/currencies/edit.vue'),

    settingsLanguages: () => import(/* webpackChunkName: "settings" */ '../components/shared/settings/languages/languages.vue'),
    settingsLanguageList: () => import(/* webpackChunkName: "settings" */ '../components/shared/settings/languages/list.vue'),
    settingsLanguageEdit: () => import(/* webpackChunkName: "settings" */ '../components/shared/settings/languages/edit.vue'),
    settingsUsers: () => import(/* webpackChunkName: "settings" */ '../components/shared/settings/users/users.vue'),
    settingsUserList: () => import(/* webpackChunkName: "settings" */ '../components/shared/settings/users/list.vue'),
    settingsUserEdit: () => import(/* webpackChunkName: "settings" */ '../components/shared/settings/users/edit.vue'),
    settingsGroups: () => import(/* webpackChunkName: "settings" */ '../components/shared/settings/groups/groups.vue'),
    settingsGroupList: () => import(/* webpackChunkName: "settings" */ '../components/shared/settings/groups/list.vue'),
    settingsGroupEdit: () => import(/* webpackChunkName: "settings" */ '../components/shared/settings/groups/edit.vue'),

    settingsVatcode: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/vatcodes/vatcode.vue'),
    settingsVatcodeList: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/vatcodes/list.vue'),
    settingsVatcodeEdit: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/vatcodes/edit.vue'),
    settingsBranchAssociation: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/branchassociation/branchassociation.vue'),
    settingsBranchAssociationList: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/branchassociation/list.vue'),
    settingsBranchAssociationEdit: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/branchassociation/edit.vue'),
    settingsTag: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/tag/tag.vue'),
    settingsTagList: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/tag/list.vue'),
    settingsTagEdit: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/tag/edit.vue'),
    settingsCorporateSector: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/corporatesector/corporatesector.vue'),
    settingsCorporateSectorList: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/corporatesector/list.vue'),
    settingsCorporateSectorEdit: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/corporatesector/edit.vue'),

    settingsExact: () => import(/* webpackChunkName: "settings" */ '../components/shared/settings/exact/exact.vue'),


    settingsWorkTypeGroups: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/worktypes/worktypegroups.vue'),
    settingsWorkTypeGroupList: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/worktypes/worktypegrouplist.vue'),
    settingsWorkTypeGroupEdit: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/worktypes/worktypegroupedit.vue'),
    settingsWorkTypeGroup: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/worktypes/worktypegroup.vue'),

    settingsWorkTypes: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/worktypes/worktypes.vue'),
    settingsWorkTypeList: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/worktypes/worktypelist.vue'),
    settingsWorkTypeEdit: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/worktypes/worktypeedit.vue'),





    reports: () => import(/* webpackChunkName: "reports" */ '../components/compliance/reports/reports.vue'),
    reportDeclarations: () => import(/* webpackChunkName: "reports" */ '../components/compliance/reports/declarations/report.vue'),
    reportRefunds: () => import(/* webpackChunkName: "reports" */ '../components/compliance/reports/refunds/report.vue'),
    reportExcises: () => import(/* webpackChunkName: "reports" */ '../components/compliance/reports/excises/report.vue'),
    reportPermits: () => import(/* webpackChunkName: "reports" */ '../components/compliance/reports/permits/report.vue'),
    reportReminderLog: () => import(/* webpackChunkName: "reports" */ '../components/compliance/reports/remideremaillog/reminderlog.vue'),
    reportInvoice: () => import(/* webpackChunkName: "reports" */ '../components/compliance/reports/invoicing/report.vue'),
   
    crmcompanies: () => import(/* webpackChunkName: "company" */ '../components/crm/companies/companies.vue'),
    crmcompanyList: () => import(/* webpackChunkName: "company" */ '../components/crm/companies/list.vue'),
    crmcompanyEdit: () => import(/* webpackChunkName: "company" */ '../components/crm/companies/edit.vue'),
    crmcompanyEditGeneral: () => import(/* webpackChunkName: "company" */ '../components/crm/companies/editgeneral.vue'),
    crmcompanyGroupByCompany: () => import(/* webpackChunkName: "company" */ '../components/crm/companies/editcompanygroup.vue'),
    crmcompanyInvoice: () => import(/* webpackChunkName: "company" */ '../components/crm/companies/editinvoice.vue'),
    crmcompanyWorkRecord: () => import(/* webpackChunkName: "company" */ '../components/crm/companies/editcompanyworkrecord.vue'),
    crmcompanyFolder: () => import(/* webpackChunkName: "company" */ '../components/crm/companies/companyfolder.vue'),
    crmcompanyFolderList: () => import(/* webpackChunkName: "company" */ '../components/crm/companies/editcompanyfolder.vue'),
    crmcompanyFolderMemo: () => import(/* webpackChunkName: "company" */ '../components/crm/companies/editcompanyfoldermemo.vue'),
    crmcompanySentInvoice: () => import(/* webpackChunkName: "company" */ '../components/crm/companies/sentlist.vue'),
    crmcompanyEditMemo: () => import(/* webpackChunkName: "company" */ '../components/crm/companies/editmemo.vue'),


    crmTimeRegistration: () => import(/* webpackChunkName: "company" */ '../components/shared/timeregistration/timeregistration.vue'),
    crmTimeRegistrationEdit: () => import(/* webpackChunkName: "company" */ '../components/shared/timeregistration/edittimeregistration.vue'),
    crmUsertime: () => import(/* webpackChunkName: "company" */ '../components/shared/timeregistration/editusertime.vue'),

    crmDeclareProposal: () => import(/* webpackChunkName: "company" */ '../components/crm/declareproposal/declareproposal.vue'),
    crmDeclareProposalList: () => import(/* webpackChunkName: "company" */ '../components/crm/declareproposal/list.vue'),
    crmDeclareProposalLine: () => import(/* webpackChunkName: "company" */ '../components/crm/declareproposal/linelist.vue'),
    crmCompanyDeclareProposal: () => import(/* webpackChunkName: "company" */ '../components/crm/declareproposal/companyproposallist.vue'),


    crmConcept: () => import(/* webpackChunkName: "crm" */ '../components/crm/invoicing/concepts/concepts.vue'),
    crmConceptList: () => import(/* webpackChunkName: "crm" */ '../components/crm/invoicing/concepts/list.vue'),
    crmConceptEdit: () => import(/* webpackChunkName: "crm" */ '../components/crm/invoicing/concepts/edit.vue'),

    crmSendto: () => import(/* webpackChunkName: "crm" */ '../components/crm/invoicing/sendto/sendto.vue'),
    crmSendtoList: () => import(/* webpackChunkName: "crm" */ '../components/crm/invoicing/sendto/list.vue'),

    crmSent: () => import(/* webpackChunkName: "crm" */ '../components/crm/invoicing/sent/sent.vue'),
    crmSentList: () => import(/* webpackChunkName: "crm" */ '../components/crm/invoicing/sent/list.vue'),
    crmSentInvoiceEdit: () => import(/* webpackChunkName: "crm" */ '../components/crm/invoicing/sent/edit.vue'),


    crmSettingsEmailTemplates: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/emailtemplates/emailtemplates.vue'),
    crmSettingsEmailTemplateEdit: () => import(/* webpackChunkName: "settings" */ '../components/crm/settings/emailtemplates/edit.vue'),
    


    testSharepoint: () => import(/* webpackChunkName: "test" */ '../components/shared/test/sharepoint.vue'),

    managementSettingsTeam: () => import(/* webpackChunkName: "crm" */ '../components/management/settings/team/team.vue'),
    managementSettingsTeamList: () => import(/* webpackChunkName: "crm" */ '../components/management/settings/team/list.vue'),
    managementSettingsTeamEdit: () => import(/* webpackChunkName: "crm" */ '../components/management/settings/team/edit.vue'),
    managementSettingsTeamChild: () => import(/* webpackChunkName: "crm" */ '../components/management/settings/team/teamchild.vue'),
    managementSettingsTeammember: () => import(/* webpackChunkName: "crm" */ '../components/management/settings/team/teammember.vue'),
    managementSettingsTeammemberList: () => import(/* webpackChunkName: "crm" */ '../components/management/settings/team/teammemberlist.vue'),
    managementSettingsTeammemberEdit: () => import(/* webpackChunkName: "crm" */ '../components/management/settings/team/memberedit.vue'),

    managementSettingsTurnoverforecast: () => import(/* webpackChunkName: "crm" */ '../components/management/settings/turnoverforecastsettings/turnoverforecastsettings.vue'),
    managementSettingsTurnoverforecastList: () => import(/* webpackChunkName: "crm" */ '../components/management/settings/turnoverforecastsettings/list.vue'),
    managementSettingsTurnoverforecastEdit: () => import(/* webpackChunkName: "crm" */ '../components/management/settings/turnoverforecastsettings/edit.vue'),

    managementreports: () => import(/* webpackChunkName: "reports" */ '../components/management/reports/reports.vue'),
    managementreportsYearlyforecast: () => import(/* webpackChunkName: "reports" */ '../components/management/reports/yearlyforecast/report.vue'),
    managementreportsMonthlyforecast: () => import(/* webpackChunkName: "reports" */ '../components/management/reports/monthlyforecast/report.vue'),
    managementreportsYearlyTurnoverPerTeam: () => import(/* webpackChunkName: "reports" */ '../components/management/reports/yearlyturnoverperteam/report.vue'),
    managementreportsRegisteredTime: () => import(/* webpackChunkName: "reports" */ '../components/management/reports/registeredtime/report.vue'),
    managementreportsComparePeriods: () => import(/* webpackChunkName: "reports" */ '../components/management/reports/compareperiods/report.vue'),
    managementreportUndeclaredhours: () => import(/* webpackChunkName: "reports" */ '../components/management/reports/undeclaredhours/report.vue'),

    crmreports: () => import(/* webpackChunkName: "reports" */ '../components/crm/reports/reports.vue'),
    crmreportsMissingRate: () => import(/* webpackChunkName: "reports" */ '../components/crm/reports/missingrate/report.vue'),

};

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters['auth/isAuthenticated']) {
        next();
        return;
    }
    next('/');
};

const ifAuthenticated = (to, from, next) => {
    if (store.getters['auth/isAuthenticated']) {
        next();
        return;
    }
    next('/login');
};

const routes = [
    {
        name: 'login',
        path: i18n.t('path.login'),
        component: lazyRoutes.login,
        beforeEnter: ifNotAuthenticated,
        meta: { title: 'title.employeeportal' }
    },
    {
        name: 'editprofile',
        path: i18n.t('path.editprofile'),
        component: lazyRoutes.editProfile,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.changepassword' }
    },
    {
        name: 'home',
        path: i18n.t('path.home'),
        component: lazyRoutes.home,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.employeeportal' }
    },
    {
        name: 'companyfilter',
        path: i18n.t('path.companyfilter'),
        component: lazyRoutes.companyfilter,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.companyfilter' }
    },
    {
        path: i18n.t('path.companies'),
        component: lazyRoutes.companies,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'companies',
                path: '',
                component: lazyRoutes.companyList,
                meta: { title: 'title.companies' },
                props: true
            },
            {
                path: ':companyId',
                component: lazyRoutes.companyEdit,
                props: true,
                children: [
                    {
                        name: 'companyEdit',
                        path: '',
                        component: lazyRoutes.companyEditGeneral,
                        meta: { title: 'title.companies' },
                        props: true
                    },
                    {
                        name: 'companyEditDeclarations',
                        path: i18n.t('path.declarations'),
                        component: lazyRoutes.companyEditDeclarations,
                        meta: { title: 'title.companies' },
                        props: true
                    },
                    {
                        name: 'companyEditRefunds',
                        path: i18n.t('path.refunds'),
                        component: lazyRoutes.companyEditRefunds,
                        meta: { title: 'title.companies' },
                        props: true
                    },
                    {
                        name: 'companyEditExcises',
                        path: i18n.t('path.excise'),
                        component: lazyRoutes.companyEditExcises,
                        meta: { title: 'title.companies' },
                        props: true
                    },
                    {
                        name: 'companyEditMemo',
                        path: i18n.t('path.memo'),
                        component: lazyRoutes.companyEditMemo,
                        meta: { title: 'title.companies' },
                        props: true
                    },
                    {
                        name: 'companyReminderLog',
                        path: i18n.t('path.reminderlog'),
                        component: lazyRoutes.companyReminderLog,
                        meta: { title: 'title.reminderlog' },
                        props: true
                    },
                    {
                        path: i18n.t('path.importfile'),
                        component: lazyRoutes.imports,
                        beforeEnter: ifAuthenticated,
                        children: [
                            {
                                name: 'companyImportFile',
                                path: '',
                                component: lazyRoutes.companyImportFile,
                                meta: { title: 'title.imports' },
                                props: true,
                            },
                            {
                                path: ':companyImportBatchId',
                                name: 'companyTransaction',
                                component: lazyRoutes.companyTransaction,
                                meta: { title: 'title.imports' },
                                props: true
                            }
                        ]
                    },
                    {
                        name: 'companyTaxData',
                        path: i18n.t('path.companytaxdata'),
                        component: lazyRoutes.companyTaxData,
                        meta: { title: 'title.companytaxdata' },
                        props: true
                    },
                    {
                        name: 'companyTaxOverviews',
                        path: i18n.t('path.companytaxoverviews'),
                        component: lazyRoutes.companyTaxOverviews,
                        meta: { title: 'title.companytaxoverviews' },
                        props: true
                    },
                    {
                        name: 'companyTaxOverviewsAddon',
                        path: i18n.t('path.companytaxoverviewsaddon'),
                        component: lazyRoutes.companyTaxOverviewsAddOn,
                        meta: { title: 'title.companytaxoverviewsaddon' },
                        props: true
                    },
                    {
                        name: 'companyTaxThresholds',
                        path: i18n.t('path.companytaxthresholds'),
                        component: lazyRoutes.companyTaxThresholds,
                        meta: { title: 'title.companytaxthresholds' },
                        props: true
                    },
                    {
                        name: 'companyHangfire',
                        path: i18n.t('path.companyhangfire'),
                        component: lazyRoutes.companyHangfire,
                        meta: { title: 'title.hangfire' },
                        props: true
                    },
                    {
                        name: 'companyCheckVatList',
                        path: i18n.t('path.checkvatlist'),
                        component: lazyRoutes.checkVat,
                        meta: { title: 'title.vatnumbercheck' },
                        props: true
                    },
                    {
                        name: 'companyPurchases',
                        path: i18n.t('path.companypurchases'),
                        component: lazyRoutes.companyPurchases,
                        meta: { title: 'title.companypurchases' },
                        props: true
                    },
                    {
                        name: 'companyOnestop',
                        path: i18n.t('path.companyonestop'),
                        component: lazyRoutes.companyOneStop,
                        meta: { title: 'title.companyonestop' },
                        props: true
                    },

                ]
            }
        ]
    },
    {
        path: i18n.t('path.crmcompanies'),
        component: lazyRoutes.crmcompanies,
        beforeEnter: ifAuthenticated,
        children: [{
            name: 'crmcompanies',
            path: '',
            component: lazyRoutes.crmcompanyList,
            meta: { title: 'title.companies' },
            props: true
        },
        {
            path: ':companyId',
            component: lazyRoutes.crmcompanyEdit,
            props: true,
            children: [
                {
                    name: 'crmcompanyEdit',
                    path: '',
                    component: lazyRoutes.crmcompanyEditGeneral,
                    meta: { title: 'title.companies' },
                    props: true
                },
                {
                    name: 'crmcompanyGroupByCompany',
                    path: i18n.t('path.crmcompanygroup'),
                    component: lazyRoutes.crmcompanyGroupByCompany,
                    meta: { title: 'title.companies' },
                    props: true
                },
                {
                    name: 'crmcompanyInvoice',
                    path: i18n.t('path.crminvoice'),
                    component: lazyRoutes.crmcompanyInvoice,
                    meta: { title: 'title.companies' },
                    props: true
                },
                {
                    name: 'crmcompanyWorkRecord',
                    path: i18n.t('path.crmworkrecord'),
                    component: lazyRoutes.crmcompanyWorkRecord,
                    meta: { title: 'title.companies' },
                    props: true
                },
                {
                    name: 'crmcompanysentinvoice',
                    path: i18n.t('path.crmsentinvoice'),
                    component: lazyRoutes.crmcompanySentInvoice,
                    meta: { title: 'title.companies' },
                    props: true
                },
                {

                    path: i18n.t('path.crmcompanyfolder'),
                    component: lazyRoutes.crmcompanyFolder,
                    beforeEnter: ifAuthenticated,
                    children: [
                        {
                            name: 'crmcompanyfolder',
                            path: '',
                            component: lazyRoutes.crmcompanyFolderList,
                            meta: { title: 'title.companies' },
                            props: true
                        },
                        {
                            name: 'crmcompanyfoldermemo',
                            path: ':folderId',
                            component: lazyRoutes.crmcompanyFolderMemo,
                            meta: { title: 'title.companies' },
                            props: true
                        }
                    ]
                },
                {
                    name: 'crmcompanyEditMemo',
                    path: i18n.t('path.crmmemo'),
                    component: lazyRoutes.crmcompanyEditMemo,
                    meta: { title: 'title.companies' },
                    props: true
                },

            ]
        }
        ]
    },
    {
        path: i18n.t('path.reports'),
        component: lazyRoutes.reports,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'reportDeclarations',
                path: i18n.t('path.declarations'),
                component: lazyRoutes.reportDeclarations,
                meta: { title: 'title.declarations' }
            },
            {
                name: 'reportRefunds',
                path: i18n.t('path.refunds'),
                component: lazyRoutes.reportRefunds,
                meta: { title: 'title.refunds' }
            },
            {
                name: 'reportExcises',
                path: i18n.t('path.excise'),
                component: lazyRoutes.reportExcises,
                meta: { title: 'title.excise' }
            },
            {
                name: 'reportPermits',
                path: i18n.t('path.permits'),
                component: lazyRoutes.reportPermits,
                meta: { title: 'title.permits' }
            },
            {
                name: 'reportReminderLog',
                path: i18n.t('path.reminderlog'),
                component: lazyRoutes.reportReminderLog,
                meta: { title: 'title.reminderlog' }
            },
            {
                name: 'reportInvoice',
                path: i18n.t('path.reportinvoicing'),
                component: lazyRoutes.reportInvoice,
                meta: { title: 'title.invoice' }
            },
            
        ]
    },
    {
        name: 'crmtimeregistration',
        path: i18n.t('path.crmtimeregistration'),
        component: lazyRoutes.crmTimeRegistrationEdit,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.timeregistration' }

    },
    {
        name: 'crmusertime',
        path: i18n.t('path.crmtimeregistrationtime'),
        component: lazyRoutes.crmUsertime,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.timeregistration' }

    },
    {
        path: i18n.t('path.crmdeclareproposal'),
        component: lazyRoutes.crmDeclareProposal,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'crmdeclareproposal',
                path: '',
                component: lazyRoutes.crmDeclareProposalList,
                meta: { title: 'title.declareproposal' }
            }, {
                name: 'crmcompanydeclareproposal',
                path: ':id',
                component: lazyRoutes.crmCompanyDeclareProposal,
                meta: { title: 'title.declareproposal' }
            },
            {
                name: 'crmdeclareproposalLine',
                path: ':id/:proposalId',
                component: lazyRoutes.crmDeclareProposalLine,
                meta: { title: 'title.declareproposal' }
            },
        ]
    },
    {
        path: i18n.t('path.settings_countries'),
        component: lazyRoutes.settingsCountries,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'countries',
                path: '',
                component: lazyRoutes.settingsCountryList,
                meta: { title: 'title.countries' }
            },
            {
                name: 'countryEdit',
                path: ':id',
                component: lazyRoutes.settingsCountryEdit,
                meta: { title: 'title.countries' }
            }
        ]
    },
    {
        path: i18n.t('path.settings_languages'),
        component: lazyRoutes.settingsLanguages,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'languages',
                path: '',
                component: lazyRoutes.settingsLanguageList,
                meta: { title: 'title.languages' }
            },
            {
                name: 'languageEdit',
                path: ':id',
                component: lazyRoutes.settingsLanguageEdit,
                meta: { title: 'title.languages' }
            }
        ]
    },
    {
        path: i18n.t('path.settings_emailTemplates'),
        component: lazyRoutes.settingsEmailTemplates,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'translations',
                path: i18n.t('path.translation'),
                component: lazyRoutes.settingsEmailTemplateTranslations,
                meta: { title: 'title.translations' }
            },
            {
                name: 'emailtemplates',
                path: ':type',
                component: lazyRoutes.settingsEmailTemplateEdit,
                meta: { title: 'title.emails' },
                props: true
            },
        ]
    },
    {
        path: i18n.t('path.settings_currencies'),
        component: lazyRoutes.settingsCurrencies,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'currencies',
                path: '',
                component: lazyRoutes.settingsCurrencyList,
                meta: { title: 'title.currencies' }
            },
            {
                name: 'currencyEdit',
                path: ':id',
                component: lazyRoutes.settingsCurrencyEdit,
                meta: { title: 'title.currencies' }
            }
        ]
    },
    {
        path: i18n.t('path.settings_users'),
        component: lazyRoutes.settingsUsers,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'users',
                path: '',
                component: lazyRoutes.settingsUserList,
                meta: { title: 'title.users' }
            },
            {
                name: 'userEdit',
                path: ':id',
                component: lazyRoutes.settingsUserEdit,
                meta: { title: 'title.users' }
            }
        ]
    },
    {
        path: i18n.t('path.settings_groups'),
        component: lazyRoutes.settingsGroups,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'groups',
                path: '',
                component: lazyRoutes.settingsGroupList,
                meta: { title: 'title.groups' }
            },
            {
                name: 'groupEdit',
                path: ':id',
                component: lazyRoutes.settingsGroupEdit,
                meta: { title: 'title.groups' }
            }
        ]
    },
    {
        path: i18n.t('path.settings_vatcodes'),
        component: lazyRoutes.settingsVatcode,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'vatcodes',
                path: '',
                component: lazyRoutes.settingsVatcodeList,
                meta: { title: 'title.vatcodes' }
            },
            {
                name: 'vatcodeEdit',
                path: ':id',
                component: lazyRoutes.settingsVatcodeEdit,
                meta: { title: 'title.vatcodes' }
            }
        ]
    },
    {
        path: i18n.t('path.settings_tags'),
        component: lazyRoutes.settingsTag,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'tags',
                path: '',
                component: lazyRoutes.settingsTagList,
                meta: { title: 'title.tags' }
            },
            {
                name: 'tagEdit',
                path: ':id',
                component: lazyRoutes.settingsTagEdit,
                meta: { title: 'title.tags' }
            }
        ]
    },
    {
        path: i18n.t('path.settings_branchassociations'),
        component: lazyRoutes.settingsBranchAssociation,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'branchassociations',
                path: '',
                component: lazyRoutes.settingsBranchAssociationList,
                meta: { title: 'title.branchassociations' }
            },
            {
                name: 'branchassociationEdit',
                path: ':id',
                component: lazyRoutes.settingsBranchAssociationEdit,
                meta: { title: 'title.branchassociations' }
            }
        ]
    },
    {
        path: i18n.t('path.settings_corporatesectors'),
        component: lazyRoutes.settingsCorporateSector,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'corporatesectors',
                path: '',
                component: lazyRoutes.settingsCorporateSectorList,
                meta: { title: 'title.corporatesectors' }
            },
            {
                name: 'corporatesectorEdit',
                path: ':id',
                component: lazyRoutes.settingsCorporateSectorEdit,
                meta: { title: 'title.corporatesectors' }
            }
        ]
    },
    {
        path: i18n.t('path.settings_concept'),
        component: lazyRoutes.crmConcept,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'crmconcept',
                path: '',
                component: lazyRoutes.crmConceptList,
                meta: { title: 'title.concepts' }
            },
            {
                name: 'crmconceptEdit',
                path: ':id',
                component: lazyRoutes.crmConceptEdit,
                meta: { title: 'title.concepts' }
            },
        ]
    },
    {
        path: i18n.t('path.settings_sendto'),
        component: lazyRoutes.crmSendto,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'crmsendto',
                path: '',
                component: lazyRoutes.crmSendtoList,
                meta: { title: 'title.concepts' }
            },
            //{
            //    name: 'crmconceptEdit',
            //    path: ':id',
            //    component: lazyRoutes.crmConceptEdit,
            //    meta: { title: 'title.concepts' }
            //},
        ]
    },
    {
        path: i18n.t('path.settings_sent'),
        component: lazyRoutes.crmSent,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'crmsent',
                path: '',
                component: lazyRoutes.crmSentList,
                meta: { title: 'title.concepts' }
            },
            {
                name: 'crmsentInvoiceEdit',
                path: ':id',
                component: lazyRoutes.crmSentInvoiceEdit,
                meta: { title: 'title.concepts' }
            },
        ]
    },
    {
        path: i18n.t('path.settings_worktypes'),
        component: lazyRoutes.settingsWorkTypeGroups,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'worktypegroups',
                path: '',
                component: lazyRoutes.settingsWorkTypeGroupList,
                meta: { title: 'title.worktypegroups' }
            },
            {
                path: ':id',
                component: lazyRoutes.settingsWorkTypeGroup,
                props: true,
                children: [
                    {
                        name: 'worktypegroupEdit',
                        path: '',
                        component: lazyRoutes.settingsWorkTypeGroupEdit,
                        meta: { title: 'title.worktypegroups' },
                        props: true
                    },
                    {
                        path: 'worktypes/',
                        component: lazyRoutes.settingsWorkTypes,
                        meta: { title: 'title.worktypes' },
                        props: true,
                        children: [
                            {
                                name: 'worktypes',
                                path: '',
                                component: lazyRoutes.settingsWorkTypeList,
                                meta: { title: 'title.worktypes' },
                                props: true
                            },
                            {
                                name: 'worktypeEdit',
                                path: ':worktypeId',
                                component: lazyRoutes.settingsWorkTypeEdit,
                                meta: { title: 'title.worktypes' },
                                props: true
                            },
                        ]
                    },
                ]
            },
        ]
    },
    {
        name: 'settingsExact',
        path: i18n.t('path.settings_exact'),
        component: lazyRoutes.settingsExact,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.settingsexact' }
    },
    {
        name: 'testSharepoint',
        path: '/testsharepoint',
        component: lazyRoutes.testSharepoint,
        beforeEnter: ifAuthenticated
    },
    {
        path: i18n.t('path.settings_teams'),
        component: lazyRoutes.managementSettingsTeam,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'teams',
                path: '',
                component: lazyRoutes.managementSettingsTeamList,
                meta: { title: 'title.teams' }
            },
            {
                path: ':id',
                component: lazyRoutes.managementSettingsTeamChild,
                props: true,
                children: [
                    {
                        name: 'teamsEdit',
                        path: '',
                        component: lazyRoutes.managementSettingsTeamEdit,
                        meta: { title: 'title.teams' },
                        props: true,
                    },
                    {
                        path: 'teammembers/',
                        component: lazyRoutes.managementSettingsTeammember,
                        children: [
                            {
                                name: 'teammembers',
                                path: '',
                                component: lazyRoutes.managementSettingsTeammemberList,
                                meta: { title: 'title.teams' },
                                props: true
                            },
                            {
                                name: 'teammemberEdit',
                                path: ':teamid',
                                props: true,
                                component: lazyRoutes.managementSettingsTeammemberEdit,
                                meta: { title: 'title.teams' }
                            },
                        ]
                    }
                ]
            },

        ]
    },
    {
        path: i18n.t('path.settings_turnoverforecast'),
        component: lazyRoutes.managementSettingsTurnoverforecast,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'turnoverforecastsettings',
                path: '',
                component: lazyRoutes.managementSettingsTurnoverforecastList,
                meta: { title: 'title.turnoverforecastsettings' }
            },
            {
                name: 'turnoverforecastsettingsEdit',
                path: ':id',
                component: lazyRoutes.managementSettingsTurnoverforecastEdit,
                meta: { title: 'title.turnoverforecastsettings' }
            },
        ]
    },
    {
        path: i18n.t('path.managementreport'),
        component: lazyRoutes.managementreports,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'reportyearlyforecast',
                path: i18n.t('path.yearlyforecast'),
                component: lazyRoutes.managementreportsYearlyforecast,
                meta: { title: 'title.yearlyforecast' }
            },
            {
                name: 'reportmonthlyforecast',
                path: i18n.t('path.monthlyforecast'),
                component: lazyRoutes.managementreportsMonthlyforecast,
                meta: { title: 'title.monthlyforecast' }
            },
            {
                name: 'reportyearlyturnoverperteam',
                path: i18n.t('path.yearlyturnoverperteam'),
                component: lazyRoutes.managementreportsYearlyTurnoverPerTeam,
                meta: { title: 'title.yearlyturnoverperteam' }
            },
            {
                name: 'reportregisteredtime',
                path: i18n.t('path.registeredtime'),
                component: lazyRoutes.managementreportsRegisteredTime,
                meta: { title: 'title.registeredtime' }
            },
            {
                name: 'reportcompareperiods',
                path: i18n.t('path.compareperiods'),
                component: lazyRoutes.managementreportsComparePeriods,
                meta: { title: 'title.compareperiods' }
            },
            {
                name: 'reportUndeclaredhours',
                path: i18n.t('path.undeclaredhours'),
                component: lazyRoutes.managementreportUndeclaredhours,
                meta: { title: 'title.undeclaredhours' }
            }
            
        ]
    },
    {
        path: i18n.t('path.crmreport'),
        component: lazyRoutes.crmreports,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'reportmissingrate',
                path: i18n.t('path.missingrate'),
                component: lazyRoutes.crmreportsMissingRate,
                meta: { title: 'title.missingrate' }
            },
            

        ]
    },
    {
        path: i18n.t('path.crmsettings_emailTemplates'),
        component: lazyRoutes.crmSettingsEmailTemplates,
        beforeEnter: ifAuthenticated,
        children: [            
            {
                name: 'crmemailtemplates',
                path: ':type',
                component: lazyRoutes.crmSettingsEmailTemplateEdit,
                meta: { title: 'title.emails' },
                props: true
            },
        ]
    },
];

const router = new VueRouter({ mode: 'history', routes });

router.afterEach((to, from) => {
    document.title = i18n.t(to.meta.title);
});

export default router;