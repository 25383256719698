import * as actionList from '../actions/companytransactionlistactions';
import moment from 'moment';

const state = {
    search: '',
    page: 0,
    startDate: null,
    endDate: null,
    departureCountryId: null,
    arrivalCountryId: null,
    transactionType: null,
    buyerType: null,
    deliveryCondition:null
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    departureCountryId: state => state.departureCountryId,
    arrivalCountryId: state => state.arrivalCountryId,
    transactionType: state => state.transactionType,
    buyerType: state => state.buyerType,
    deliveryCondition: state => state.deliveryCondition,    
};

const actions = {
    [actionList.COMPANYTRANSACTIONLIST_SEARCH]: ({ commit }, search) => {
        commit(actionList.COMPANYTRANSACTIONLIST_SEARCH, search);
    },
    [actionList.COMPANYTRANSACTIONLIST_PAGE]: ({ commit }, page) => {
        commit(actionList.COMPANYTRANSACTIONLIST_PAGE, page);
    },
    [actionList.COMPANYTRANSACTIONLIST_STARTDATE]: ({ commit }, startDate) => {
        commit(actionList.COMPANYTRANSACTIONLIST_STARTDATE, startDate);
    },
    [actionList.COMPANYTRANSACTIONLIST_ENDDATE]: ({ commit }, endDate) => {
        commit(actionList.COMPANYTRANSACTIONLIST_ENDDATE, endDate);
    },
    [actionList.COMPANYTRANSACTIONLIST_DEPARTURECOUNTRYID]: ({ commit }, departureCountryId) => {
        commit(actionList.COMPANYTRANSACTIONLIST_DEPARTURECOUNTRYID, departureCountryId);
    },
    [actionList.COMPANYTRANSACTIONLIST_ARRIVALCOUNTRYID]: ({ commit }, arrivalCountryId) => {
        commit(actionList.COMPANYTRANSACTIONLIST_ARRIVALCOUNTRYID, arrivalCountryId);
    },
    [actionList.COMPANYTRANSACTIONLIST_TRANSACTIONTYPE]: ({ commit }, transactionType) => {
        commit(actionList.COMPANYTRANSACTIONLIST_TRANSACTIONTYPE, transactionType);
    },
    [actionList.COMPANYTRANSACTIONLIST_BUYERTYPE]: ({ commit }, buyerType) => {
        commit(actionList.COMPANYTRANSACTIONLIST_BUYERTYPE, buyerType);
    },
    [actionList.COMPANYTRANSACTIONLIST_DELIVERYCONDITION]: ({ commit }, deliveryCondition) => {
        commit(actionList.COMPANYTRANSACTIONLIST_DELIVERYCONDITION, deliveryCondition);
    }    
};

const mutations = {
    [actionList.COMPANYTRANSACTIONLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionList.COMPANYTRANSACTIONLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionList.COMPANYTRANSACTIONLIST_STARTDATE]: (state, startDate) => {
        state.page = 0;
        state.startDate = startDate;
    },
    [actionList.COMPANYTRANSACTIONLIST_ENDDATE]: (state, endDate) => {
        state.page = 0;
        state.endDate = endDate;
    },
    [actionList.COMPANYTRANSACTIONLIST_DEPARTURECOUNTRYID]: (state, departureCountryId) => {
        state.page = 0;
        state.departureCountryId = departureCountryId;
    },
    [actionList.COMPANYTRANSACTIONLIST_ARRIVALCOUNTRYID]: (state, arrivalCountryId) => {
        state.page = 0;
        state.arrivalCountryId = arrivalCountryId;
    },
    [actionList.COMPANYTRANSACTIONLIST_TRANSACTIONTYPE]: (state, transactionType) => {
        state.page = 0;
        state.transactionType = transactionType;
    },
    [actionList.COMPANYTRANSACTIONLIST_BUYERTYPE]: (state, buyerType) => {
        state.page = 0;
        state.buyerType = buyerType;
    },
    [actionList.COMPANYTRANSACTIONLIST_DELIVERYCONDITION]: (state, deliveryCondition) => {
        state.page = 0;
        state.deliveryCondition = deliveryCondition;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};