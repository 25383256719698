export default {
    components: {
    },
    props: {
        value: String,
        validation: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: '-'
        },
        inputclass: {
            type: String,
            default: ''
        },
        readonly: { 
            type: Boolean,
            default: false
        }
    },
    name: 'InputText',
    data() {
        return {
        };
    },
    computed: {
        isRequired() {
            return this.validation.includes('required');
        }
    },
    methods: {
    }
};