import axios from 'axios';
import Select2 from 'v-select2-component';

export default {
    components: {
        'select2': Select2
    },
    name: 'Select3pSupplier',
    props: {
        value: [Number],
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            companies: []
        };
    },
    computed: {
        options() {
            return this.companies.map(x => {
                return { id: x.id, text: x.nameShort };
            });
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            axios.get(APIURL + '/shared/companies/company/suppliers')
                .then(resp => this.companies = resp.data);
        },
    }
};