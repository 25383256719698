let settings = [
    {
        name: "vatcodes",
        title: "vatcodes",
        params:{},
        seqno: 1
    },
    {
        name: "tags",
        title: "tags",
        params:{},
        seqno: 2
    }, {
        name: "branchassociations",
        title: "branchassociations",
        params:{},
        seqno: 3
    },
    {
        name: "corporatesectors",
        title: "corporatesectors",
        params:{},
        seqno: 4
    }, {
        name: "worktypegroups",
        title: "worktypegroups",
        params:{},
        seqno: 5
    },
    {
        name: "crmemailtemplates",
        title: "emailtemplates",
        params: {
            type: 3
        },
        seqno: 6
    }
];
let companies = [
    {
        name: "crmcompanies",
        params:{},
        title: 'overview',
        seqno: 1
    },
    {
        name: "companyfilter",
        params:{},
        title: 'selections',
        seqno: 2
    },
];
let reports = [{
    name: "reportmissingrate",
    params:{},
    title: 'missingrate',
    seqno: 1
}];
export default {
    settings: settings,
    companies: companies,
    reports: reports
}
