let settings = [
    {
        name: 'teams',
        title: 'teams',
        params:{},
        seqno: 1
    },
    {
        name: 'turnoverforecastsettings',
        title: 'turnoverforecastsettings',
        params:{},
        seqno: 1
    },
    ];
let companies = [];
let reports = [
    {
        name: 'reportyearlyforecast',
        title: 'yearlyforecast',
        params:{},
        seqno: 1
    },
    {
        name: 'reportmonthlyforecast',
        title: 'monthlyforecast',
        params:{},
        seqno: 2
    },
    {
        name: 'reportyearlyturnoverperteam',
        title: 'yearlyturnoverperteam',
        params:{},
        seqno: 3
    },
    {
        name: 'reportregisteredtime',
        title: 'registeredtime',
        params:{},
        seqno: 4
    },
    {
        name: 'reportcompareperiods',
        title: 'compareperiods',
        params:{},
        seqno: 5
    },
    {
        name: "reportUndeclaredhours",
        title: 'undeclaredhours',
        params:{},
        seqno: 6
    },];
let timeregistration = [];
let invoicing = [];
export default {
    settings: settings,
    companies: companies,
    reports: reports,
    timeregistration: timeregistration,
    invoicing: invoicing
}
