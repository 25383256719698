var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    class: _vm.inputclass,
    attrs: { type: "radio", "true-value": true, "false-value": false },
    domProps: { checked: _vm.value },
    on: {
      change: function(e) {
        _vm.$emit("input", e.target.checked)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }