import axios from 'axios';
import Select2 from 'v-select2-component';

export default {
    components: {
        'select2': Select2
    },
    name: 'SelectTimezone',
    props: {
        value: [String],
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type:String,
            default: null
        }
    },
    data() {
        return {
            timezones:[]
        };
    },
    computed: {
        options() {
            return this.timezones.map(x => {
                return {id:x.id, text: x.displayName};
            });
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            axios.get(APIURL + '/shared/settings/language/timezonelist')
                .then(resp => this.timezones=resp.data);
        },
    }
};