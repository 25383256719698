import * as actionList from '../actions/reportexcisesactions';
import moment from 'moment';

const state = { 
    search:'', 
    page:0, 
    startDate: moment().startOf('month').toDate(), 
    endDate: moment().endOf('month').toDate(), 
    userId:-1, 
    countryId:null, 
    status: 1,
    threepsupplier: null
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    userId: state => state.userId,
    countryId: state => state.countryId,
    status: state => state.status,
    threepsupplier: state => state.threepsupplier
};

const actions = {
    [actionList.REPORTEXCISES_SEARCH]: ({ commit }, search) => {
        commit(actionList.REPORTEXCISES_SEARCH, search);
    },
    [actionList.REPORTEXCISES_PAGE]: ({ commit }, page) => {
        commit(actionList.REPORTEXCISES_PAGE, page);
    },
    [actionList.REPORTEXCISES_STARTDATE]: ({ commit }, startDate) => {
        commit(actionList.REPORTEXCISES_STARTDATE, startDate);
    },
    [actionList.REPORTEXCISES_ENDDATE]: ({ commit }, endDate) => {
        commit(actionList.REPORTEXCISES_ENDDATE, endDate);
    },
    [actionList.REPORTEXCISES_USER]: ({ commit }, userId) => {
        commit(actionList.REPORTEXCISES_USER, userId);
    },
    [actionList.REPORTEXCISES_COUNTRY]: ({ commit }, countryId) => {
        commit(actionList.REPORTEXCISES_COUNTRY, countryId);
    },
    [actionList.REPORTEXCISES_STATUS]: ({ commit }, status) => {
        commit(actionList.REPORTEXCISES_STATUS, status);
    },
    [actionList.REPORTEXCISES_THREEPSUPPLIER]: ({ commit }, threepsupplier) => {
        commit(actionList.REPORTEXCISES_THREEPSUPPLIER, threepsupplier);
    }
};

const mutations = {
    [actionList.REPORTEXCISES_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionList.REPORTEXCISES_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionList.REPORTEXCISES_STARTDATE]: (state, startDate) => {
        state.page = 0;
        state.startDate = startDate;
    },
    [actionList.REPORTEXCISES_ENDDATE]: (state, endDate) => {
        state.page = 0;
        state.endDate = endDate;
    },
    [actionList.REPORTEXCISES_USER]: (state, userId) => {
        state.page = 0;
        state.userId = userId;
    },
    [actionList.REPORTEXCISES_COUNTRY]: (state, countryId) => {
        state.page = 0;
        state.countryId = countryId;
    },
    [actionList.REPORTEXCISES_STATUS]: (state, status) => {
        state.page = 0;
        state.status = status;
    },
    [actionList.REPORTEXCISES_THREEPSUPPLIER]: (state, threepsupplier) => {
        state.page = 0;
        state.threepsupplier = threepsupplier;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};