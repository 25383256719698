import { render, staticRenderFns } from "./select2.vue?vue&type=template&id=7c522828&"
import script from "./select2.js?vue&type=script&lang=js&"
export * from "./select2.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projecten\\EigenSteil\\Employee Portal\\Frontend\\EmployeePortal.Portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c522828')) {
      api.createRecord('7c522828', component.options)
    } else {
      api.reload('7c522828', component.options)
    }
    module.hot.accept("./select2.vue?vue&type=template&id=7c522828&", function () {
      api.rerender('7c522828', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/shared/common/inputs/select2.vue"
export default component.exports