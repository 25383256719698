import { render, staticRenderFns } from "./readonlytext.vue?vue&type=template&id=ace29f20&"
import script from "./readonlytext.js?vue&type=script&lang=js&"
export * from "./readonlytext.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projecten\\EigenSteil\\Employee Portal\\Frontend\\EmployeePortal.Portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ace29f20')) {
      api.createRecord('ace29f20', component.options)
    } else {
      api.reload('ace29f20', component.options)
    }
    module.hot.accept("./readonlytext.vue?vue&type=template&id=ace29f20&", function () {
      api.rerender('ace29f20', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/shared/common/readonly/readonlytext.vue"
export default component.exports