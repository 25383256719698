var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("buttonsave"),
      _vm._v(" "),
      _vm.hasDefaultSlot
        ? _c(
            "button",
            {
              staticClass: "dropdown btn btn-secondary btn-icon",
              attrs: {
                type: "button",
                id: "dropdownMenu2",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              }
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasDefaultSlot
        ? _c(
            "div",
            {
              staticClass: "dropdown-menu",
              attrs: { "aria-labelledby": "dropdownMenu2" }
            },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "fas fa-ellipsis-h" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }