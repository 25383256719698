import * as actionList from '../actions/reportyearlyforecastactions';
import moment from 'moment';

const state = {
    search: '',
    page: 0,
    year: moment().year(),
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    year: state => state.year,

};

const actions = {
    [actionList.REPORTYEARLYFORECAST_SEARCH]: ({ commit }, search) => {
        commit(actionList.REPORTYEARLYFORECAST_SEARCH, search);
    },
    [actionList.REPORTYEARLYFORECAST_PAGE]: ({ commit }, page) => {
        commit(actionList.REPORTYEARLYFORECAST_PAGE, page);
    },
    [actionList.REPORTYEARLYFORECAST_YEAR]: ({ commit }, year) => {
        commit(actionList.REPORTYEARLYFORECAST_YEAR, year);
    },
};

const mutations = {
    [actionList.REPORTYEARLYFORECAST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionList.REPORTYEARLYFORECAST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionList.REPORTYEARLYFORECAST_YEAR]: (state, year) => {
        state.page = 0;
        state.year = year;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};