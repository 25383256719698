export default {
    components: {
    },
    name: 'InputMoney',
    props: {
        value: {
            type: Number,
            default: 0
        },
        currency: {
            type: String,
            default: '€'
        },
        readonly: {
            type: Boolean,
            default: false
        },
        widthClass: {
            type: String,
            default: 'wd-80'
        }
    },
    data() {
        return {
        };
    },
    computed: {
        options() {
            return {
                decimalPlaces: '2',
                digitGroupSeparator: '.',
                decimalCharacter: ',',
                decimalCharacterAlternative: '.',
                currencySymbol: this.currency,
                currencySymbolPlacement: 'p',
                roundingMethod: 'U',
                readOnly: this.readonly,
                
            };
        },
        uniqid() {
            return 'id_' + (new Date()).getTime();            
        }
    },
};