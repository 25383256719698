const nl = {
    'culture': 'nl-NL',
    'actions': {
        'add': 'Toevoegen',
        'assignproposal': 'Doorzetten naar collega',
        'back': {
            'imports': 'Terug naar importbestanden',
            'workgroups': 'Terug naar groepen werksoorten',
            'teams': 'Terug naar Teams'
        },
        'close': 'Sluiten',
        'delete': 'Verwijderen',
        'edit': 'Bewerken',
        'export': 'Exporteren',
        'login': 'Inloggen',
        'logout': 'Uitloggen',
        'ok': 'Ok',
        'recalculate': 'Herberekenen',
        'refresh': 'Vernieuwen',
        'save': 'Opslaan',
        'savenew': 'Opslaan en nieuw',
        'search': 'Zoeken',
        'importfile': 'Importeer bestand',
        'closeweek': 'Weekstaat sluiten',
        'generateproposal': 'Declaratievoorstellen genereren',
        'finishproposal': 'Declaratievoorstel afronden',
        'mergeinvoice': 'Samenvoegen',
        'approveinvoice': 'Goedkeuren',
        'fixvatnumbers': 'Fix ongeldige BTW-nrs',
        'downloadpdf': 'Download PDF',
        'generatepdf': 'Genereer PDF',
        'printinvoice': 'Factuur afdrukken',
        'sendinvoice': 'Factuur verzenden',
        'template': 'Sjabloon',
        'makecreditinvoice': 'Creditnota',
        'next': 'Volgende',
        'updatestatus': 'Afkeuren',
        'delay': 'Doorschuiven',
        'activate': 'Activeren',
        'reactivate': 'Heractiveren',
        'deactivate': 'Deactiveren',
        'transfer': 'Overboeken',
        'view': 'Bekijken',
        'reopen': 'Heropenen',
        'cancel': 'Annuleren',
        'send': 'Verzenden',
        'invoicetransactions': 'Transacties',
        'invoicenil': 'Nihil',
        'printinvoiceall': 'Print alle facturen'
    },
    'confirm': {
        'delete': '<strong>Weet u het zeker?</strong><br />Record verwijderen',
        'leaveWithoutSave': '<strong>Weet u het zeker?</strong><br />Er zijn niet opgeslagen wijzigingen',
        'wanttoreplace': 'Dit bestand is al eerder geïmporteerd op date. <br>Opnieuw importeren?',
        'savehours': '<strong>Weet u het zeker?</strong><br />Weekstaat sluiten?',
        'openproposal': 'Wilt u het voorstel heropenen?',
        'reopen': 'Wilt u het declaratievoorstel heropenen?',
        'approveinvoice': 'Wilt u de geselecteerde factuur/facturen goedkeuren?',
        'deniedstatus': 'Wilt u afkeuren?',
        'delayedstatus': 'Wilt u het hele declaratievoorstel doorschuiven?',
        'assignproposal': 'Wilt u het hele declaratievoorstel doorzetten naar een collega?'
    },
    'dashboard': {
        'declarationsUpcomingDeadlines': 'Aangifte-deadlines < 30 dagen',
        'excisesUpcomingDeadlines': 'Accijns-deadlines < 30 dagen',
        'permitsUpcomingDeadlines': 'Bescheinigung-deadlines < 30 dagen',
        'refundsUpcomingDeadlines': 'Teruggaaf-deadlines < 30 dagen',
        'openForAll': ' totaal niet afgerond',
        'openForMe': ' van mij niet afgerond',
    },
    'companyType': {
        'description': 'Soort relatie',
        'unknown': 'Onbekend',
        'customer': 'Klant | Klanten',
        'agent': 'Agent | Agenten',
        'thirdParty': '3P Supplier | 3P Suppliers',
        'employee': 'Medewerker',
        'person': 'Persoonsrelatie'
    },
    'Enum': {
        'transactiontype': {
            'SALE': 'VERKOOP',
            'REFUND': 'TERUGBETALING',
            'RETURN': 'RETOUR',
            'FC_TRANSFER': 'FC_TRANSFER',
            'INBOUND': 'INBOUND',
        },
        'buyertype': {
            'None': 'Geen',
            'B2B': 'B2B',
            'B2C': 'B2C'
        },
        'deliverycondition': {
            'None': 'Geen',
            'DAP': 'DAP',
            'DDP': 'DDP',
            'XXX': 'XXX',
            'CONSIGNMENT_BY_POST': 'ZENDING PER POST'
        },
        'taxrate': {
            'High': 'Hoog',
            'Low': 'Laag',
            'None': 'Geen',
        },
        'addresstype': {
            'PostalAddress': 'Postadres',
            'VisitingAddress': 'Bezoekadres'
        },
        'operatingarea': {
            'National': 'Nationaal',
            'International': 'Internationaal',
            'Both': 'Beide'
        },
        'profitmotive': {
            'Profit': 'Winst',
            'NonProfit': 'Non-profit'
        },
        'sendinvoiceby': {
            'None': 'Onbekend',
            'Paper': 'Papier',
            'Email': 'E-mail'
        },
        'sendinvoiceto': {
            'None': 'Onbekend',
            'Self': 'naar klant zelf',
            'OtherCompany': 'Ander bedrijf'
        },
        'repeatingperiod': {
            'Never': 'Nooit',
            'Monthly': 'Maandelijks',
            'Quarterly': 'Driemaandelijks',
            'Yearly': 'Jaarlijks'
        },
        'correspondencestatus': {
            'WorkInProgress': 'Werk in uitvoering',
            'ToSend': 'Te verzenden',
            'Sent': 'Verzonden',
            'Concept': 'Concept',
            'Received': 'Ontvangen'
        },
        'correspondencetype': {
            'LetterIncoming': 'Brief Inkomend',
            'LetterOutgoing': 'Brief Uitgaand',
            'Email': 'E-mail',
            'Fax': 'Fax',
            'Memo': 'Memo',
            'Document': 'Document'
        },
        'timestatus': {
            'open': 'Open',
            'close': 'Dichtbij'
        },
        'proposallinestatus': {
            'undecided': 'Onbeslist',
            'approved': 'Goedgekeurd',
            'denied': 'Geweigerd',
            'delayed': 'Doorgeschoven'
        },
        'proposallinetype': {
            'timeregistration': 'Tijdregistratie',
            'invoicelinerepeating': 'Factuurregel Herhalen'
        },
        'invoicestatus': {
            'none': 'Geen',
            'donotinvoice': 'Nee',
            'invoicetransactions': 'Transacties',
            'invoicenil': 'Nihil',
            'doinvoice': 'Ja'
        },
        'domain': {
            'National': 'Nationaal',
            'International': 'Internationaal'
        },
        'branch': {
            'Advice': 'Advies',
            'Compliance': 'Compliance'
        }
    },
    'error': {
        'saveChanges': 'Er is een probleem opgetreden tijdens het opslaan van de gegevens',
        'deleteRecordConflict': 'Dit record kan niet worden verwijderd',
        'reopen': 'Er is een probleem opgetreden tijdens het heropenen van het declaratievoorstel',
        'approveinvoice': 'Er is een probleem opgetreden tijdens het goedkeuren van de factuur',
        'yearexists': 'Jaar bestaat al!'
    },
    'format': {
        'date': 'DD-MM-YYYY',
        'dateTime': 'DD-MM-YYYY HH:mm',
        'timezone': 'UTC',
        'time': 'HH:mm',
    },
    'path': {
        'companies': '/compliance/relatie',
        'crmcompanies': '/crm/relatie',
        'companyfilter': '/bedrijfsfilter',
        'crmcompanygroup': 'crm-opdracht',
        'crminvoice': 'invoicing',
        'crmworkrecord': 'werkdossier',
        'crmcompanyfolder': 'memos',
        'timeregistration': '/tijdregistratie',
        'crmtimeregistration': '/tijdregistratie/tijd',
        'crmtimeregistrationtime': '/tijdregistratie/gebruikstijd',
        'crmdeclareproposal': '/tijdregistratie/declaratievoorstellen',
        'crmsentinvoice': 'bedrijfheeftfacturenverzonden',
        'crmmemo': 'opmerkingen',
        'customers': '/klant',
        'declarations': 'aangiftes',
        'permits': 'bescheinigungen',
        'excise': 'accijns',
        'memo': 'memo',
        'home': '/',
        'login': '/login',
        'editprofile': '/editprofile',
        'reports': '/compliance/rapportage',

        'refunds': 'teruggaafverzoeken',
        'reportinvoicing': 'facturatie',
        'reminderlog': 'Herinneringen',
        'importfile': 'importfile',
        'settings': '/instellingen',
        'settings_countries': '/compliance/instellingen/land',
        'settings_currencies': '/compliance/instellingen/valuta',
        'settings_emailTemplates': '/compliance/instellingen/emailsjabloon',
        'settings_languages': '/shared/instellingen/taal',
        'settings_users': '/shared/instellingen/gebruikers',
        'settings_groups': '/shared/instellingen/groepen',
        'settings_exact': '/shared/instellingen/exact',
        'companytransaction': 'companytransaction',
        'companytaxdata': 'verkopen',
        'companypurchases': 'inkopen',
        'companytaxoverviews': 'belastingoverzichten',
        'companytaxoverviewsaddon': 'belastingoverzichtenaddon',
        'companytaxthresholds': 'drempels',
        'companyonestop': 'onestopstop',
        'translation': 'vertaling',
        'companyhangfire': 'hangfire',
        'checkvatlist': 'btw-nummers',
        'settings_vatcodes': '/crm/instellingen/btwcodes',
        'settings_tags': '/crm/instellingen/tags',
        'settings_branchassociations': '/crm/instellingen/brancheverenigingen',
        'settings_corporatesectors': '/crm/instellingen/bedrijfssectoren',
        'settings_worktypes': '/crm/instellingen/werksoorten',
        'settings_invoicing': 'facturatie',
        'settings_concept': '/facturatie/concepten',
        'settings_sendto': '/facturatie/verzendennaar',
        'settings_sent': '/facturatie/verzonden',
        'settings_teams': '/management/instellingen/teams',
        'settings_turnoverforecast': '/management/instellingen/omzetprognose',
        'managementreport': '/management/rapportage',
        'undeclaredhours': '/compliance/rapportage/nietdeclarabeleuren',
        'yearlyforecast': '/management/rapportage/jaarlijkseprognose',
        'monthlyforecast': '/management/rapportage/maandelijkseprognose',
        'yearlyturnoverperteam': '/management/rapportage/jaarlijkseomzetperteam',
        'registeredtime': '/management/rapportage/geregistreerdetijd',
        'compareperiods': '/management/rapportage/vergelijkperiodes',
        'missingrate': '/crm/rapportage/ontbrekendtarief',
        'crmsettings_emailTemplates': '/crm/instellingen/emailsjabloon',
    },
    'periods': {
        'januaryShort': 'Jan',
        'februaryShort': 'Feb',
        'marchShort': 'Maa',
        'aprilShort': 'Apr',
        'mayShort': 'Mei',
        'juneShort': 'Jun',
        'julyShort': 'Jul',
        'augustShort': 'Aug',
        'septemberShort': 'Sep',
        'octoberShort': 'Okt',
        'novemberShort': 'Nov',
        'decemberShort': 'Dec',
        'quarter1Short': 'Q1',
        'quarter2Short': 'Q2',
        'quarter3Short': 'Q3',
        'quarter4Short': 'Q4',
        'january': 'Januari',
        'february': 'Februari',
        'march': 'Maart',
        'april': 'April',
        'may': 'Mei',
        'june': 'Juni',
        'july': 'Juli',
        'august': 'Augustus',
        'september': 'September',
        'october': 'Oktober',
        'november': 'November',
        'december': 'December',
        'quarter1': '1e kwartaal',
        'quarter2': '2e kwartaal',
        'quarter3': '3e kwartaal',
        'quarter4': '4e kwartaal',
        'firstHalfYear': '1e half jaar',
        'secondHalfYear': '2e half jaar',
        'yearDeclarationShort': 'Jaar',
        'yearDeclaration': 'Jaarverklaring',
        'trimester': 'Trimester'
    },
    'status': {
        'closed': 'Afgerond',
        'status': 'Status',
        'loading': 'Laden...',
        'norecords': 'Geen gegevens gevonden',
        'loginfailed': 'Gebruikersnaam en/of wachtwoord onbekend',
        'open': 'Niet afgerond',
        'invalidpassword': 'Het wachtwoord is ongeldig',
        'via': 'Via',
        'alreadytaken': 'Is al in gebruik',
        'invaliddate': 'Ongeldige datum'
    },
    'success': {
        'saveChanges': 'De gegevens zijn succesvol opgeslagen',
        'deleteRecord': 'De gegevens zijn succesvol verwijderd',
        'reopen': 'Het declaratievoorstel is heropend',
        'approveinvoice': 'De geselecteerde facturen zijn goedgekeurd',
        'register': 'Het registreren is voltooid',
        'unregister': 'De registratie is ongedaan gemaakt'
    },
    'title': {
        'addnewrecord': 'Nieuw record toevoegen',
        'branchassociations': 'Brancheverenigingen',
        'changesettings': 'Wijzig instellingen',
        'companies': 'Relaties',
        'companyfilter': 'Bedrijfsfilter',
        'currencies': 'Valuta',
        'countries': 'Landen',
        'companygroup': 'Opdrachten',
        'companyfolder': 'Memo\'s',
        'companyonestop':'One Stop Stop',
        'compareperiods': 'Vergelijk periodes',
        'contactperson': 'Contactpersoon',
        'concepts': 'Concepten',
        'code': 'Code',
        'customers': 'Klanten',
        'declarations': 'Aangiftes',
        'editrecord': 'Record wijzigen',
        'emails': 'E-mails',
        'emailtemplates': 'E-mailsjablonen',
        'employeeportal': 'Medewerkerportaal',
        'excise': 'Accijns',
        'general': 'Algemeen',
        'groups': 'Opdrachten',
        'home': 'Home',
        'imports': 'Imports',
        'invoice': 'Factuur',
        'invoicing': 'Facturatie',
        'languages': 'Talen',
        'navigation': 'Navigatie',
        'onlinesales': 'Online verkopen',
        'permits': 'Bescheinigungen',
        'refunds': 'Teruggaafverzoeken',
        'reminderlog': 'Herinneringen',
        'reports': 'Rapportages',
        'readytosend': 'Te verzenden',
        'sent': 'Verzonden',
        'settings': 'Instellingen',
        'translations': 'Vertalingen',
        'users': 'Gebruikers',
        'memo': 'Memo',
        'changepassword': 'Verander wachtwoord',
        'editprofile': 'Bewerk profiel',
        'vatRegimes': 'BTW tarieven',
        'updatepassword': 'Vernieuw wachtwoord',
        'vatthresholddistancesales': 'BTW drempel afstandsverkopen',
        'companytaxdata': 'Verkopen',
        'companypurchases': 'Inkopen',
        'companytaxoverviews': 'Belastingoverzichten',
        'companytaxoverviewsaddon': 'Belastingoverzichten Toevoeging',
        'companytaxthresholds': 'Drempels',
        'corporatesectors': 'Bedrijfssectoren',
        'timeregistration': 'Tijdregistratie',
        'tag': 'Tag',
        'tags': 'Tags',
        'testenvironment': 'TESTVERSIE',
        'usertime': 'Weekstaten',
        'hangfire': 'Hangfire',
        'vatnumbercheck': 'BTW-nummers',
        'vatnumber': 'BTW-nummer',
        'vatcodes': 'Btwcodes',
        'worktypegroups': 'Groepen werksoorten',
        'worktypes': 'Werksoorten',
        'workrecord': 'Werkdossier',
        'overview': 'Overzicht',
        'selections': 'Selecties',
        'declareproposal': 'Declaratievoorstellen',
        'declareproposalline': 'Declaratievoorstelregels',
        'sendto': 'Klaar voor facturatie',
        'sentinvoice': 'Gefactureerd',
        'conceptinvoice': 'Conceptfactuur',
        'exact': 'Exact',
        'settingsexact': 'Instellingen Exact',
        'invoicestatus': 'Selecteer de soort aangifte',
        'team': 'Team',
        'teams': 'Teams',
        'teammember': 'Teamlid',
        'teammembers': 'Teamleden',
        'turnoverforecastsettings': 'Prognoses',
        'yearlyforecast': 'Jaarlijkse prognose',
        'monthlyforecast': 'Maandelijkse prognose',
        'yearlyturnoverperteam': 'Jaarlijkse omzet per team',
        'registeredtime': 'Geregistreerde tijd',
        'missingrate': 'Ontbrekend tarief',
        'undeclaredhours': 'Niet-declarabele uren',
        'preview': 'Voorbeeld',
        'remarks': 'Opmerkingen',
    },
    'validations': {
        'messages': {
            '_default': ''
        }
    },
    'module': {
        'crm': 'CRM',
        'compliance': 'Compliance',
        'advice': 'Advies',
        'management': 'Management'
    },
    'absolutedifference': 'Absoluut verschil',
    'accountname': 'Ten name van',
    'active': 'Actief',
    'actualturnover': 'Werkelijke omzet',
    'administration': ' Administratie',
    'amountinvoiced': 'Ontvangen',
    'inactive': 'Niet actief',
    'address': 'Adres',
    'administrator': 'Algemeen beheerder',
    'and': 'en',
    'amountreceived': 'Ontvangen',
    'amount': 'Bedrag',
    'agent': 'Agenten',
    'agentDescription': 'Agenten per land',
    'availablehourperweek': 'Beschikbare uren per week',
    'arrivalcountry': 'Land aankomst',
    'arrivalcountryShort': 'Aankomst',
    'bankname': 'Naam bank',
    'b2bvatincluded': 'B2B BTW inclusief',
    'bic': 'BIC',
    'blocked': 'Geblokkeerd',
    'buyername': 'Koper',
    'city': 'Plaats',
    'code': 'Code',
    'company': 'Relatie',
    'compareperiods': 'Vergelijk periodes',
    'compliancelines': 'Compliance',
    'confirmpassword': 'Bevestig wachtwoord',
    'contact': 'Contact',
    'contactpersoon': 'Contactpersoon',
    'contents': 'Inhoud',
    'country': 'Land',
    'countryDeclaration': 'Land aangifte',
    'corporatesector': 'Bedrijfssectoren',
    'currency': 'Valuta',
    'customer': 'Klant',
    'customercount': 'Aantal klanten',
    'customersince': 'Klant sinds',
    'customerlisting': 'Klantenlisting',
    'complianceonly': 'Uitsluitend compliance',
    'companyassociation': 'Bedrijfsvereniging',
    'companygroup': 'Opdracht',
    'companyfolder': 'Map',
    'contactperson': 'Contactpersonen',
    'credit': 'Credit',
    'creditcompleteamount': 'Volledig crediteren',
    'creditpartially': 'Deels crediteren',
    'currentpassword': 'Huidig wachtwoord',
    'datasource': 'Bron',
    'day': 'Dag | Dagen',
    'date': 'Datum',
    'dateOfImport': 'Importdatum',
    'deadline': 'Deadline',
    'deadlineBetween': 'Deadline tussen',
    'deadlineIn': 'Deadline in',
    'debit': 'Debet',
    'department': 'Vestiging',
    'description': 'Omschrijving',
    'departurecountry': 'Land vertrek',
    'departurecountryShort': 'Vertrek',
    'directory': 'Map',
    'donotinvoice': 'Niet factureren',
    'sellercountry': 'Land verkoper',
    'deliverycondition': 'Leveringsconditie',
    'declarationproposal': 'Declaratievoorstel',
    'descriptiononinvoiceen': 'Omschrijving op factuur (EN)',
    'descriptiononinvoicenl': 'Omschrijving op factuur (NL)',   
    'vatcountry': 'Land BTW',
    'vatcountryShort': 'Land BTW',
    'transactiontype': 'Type transactie',
    'transactiontypeShort': 'Transactie',
    'buyertype': 'Type koper',
    'buyervatnumber': 'BTW nummer',
    'debtornumber': 'Debiteurnummer',
    'decisionreceived': 'Beschikking',
    'valueinclvatoriginal': 'Totaal incl BTW',
    'valueinclvateuro': 'Totaal incl BTW (euro)',
    'valueexclvatoriginal': 'Totaal excl BTW',
    'valueexclvateuro': 'Totaal excl BTW (euro)',
    'inclvatoriginal': 'Inc. BTW (orig)',
    'inclvateuro': 'Inc. BTW (euro)',
    'exclvatoriginal': 'Excl. BTW (orig)',
    'exclvateuro': 'Excl. BTW (euro)',
    'enddate': 'Einddatum',
    'enddateShort': 'Eind',
    'email': 'E-mail',
    'emailaddress': 'E-mailadres',
    'emailFirst': 'Eerste bericht',
    'emailReminder': 'Herinnering',
    'exporttoexcel':'Exporteren naar Excel',
    'crmemailFirst': 'Eerste herinnering',
    'crmemailReminder': 'Aanmaning',
    'emailaddressadministration': 'E-mailadres administratie',
    'employeeportal': 'Medewerkerportaal',
    'enableonestopshop':'One Stop Shop',
    'excise': 'Accijns',
    'extension': 'Uitstel',
    'extensionPossible': 'Uitstel mogelijk',
    'externalid': 'Dossiernummer',
    'externaltaken': 'Dossiernummer is in gebruik. Gelieve een ander nummer in te vullen',
    'filename': 'Bestandsnaam',
    'firstMessage': 'Eerste bericht',
    'firstname': 'Voornaam',
    'from': 'Van',
    'file': 'Het dossier',
    'validfrom': 'Geldig vanaf',
    'validuntil': 'Geldig tot',
    'general': 'Algemeen',
    'group': 'Opdracht',
    'hastaxrepresentative': 'Heeft fiscaal vertegenwoordiger',
    'high': 'Hoog',
    'highrate': 'Hoog tarief',
    'hours': 'Uren',
    'hourlyrate': 'Uurtarief',
    'hourlyrateforcast': 'Uurtarief prognose',
    'hourlyrateinvoice': 'Uurtarieffactuur per team',
    'lowrate': 'Lage tarief',
    'invoiced': 'Gefactureerd',
    'iban': 'IBAN',
    'icp': 'ICP',
    'ICP': 'ICP',
    'insideEU': 'Binnen EU',
    'outsideEU': 'Buiten EU',
    'inforeceived': 'Binnen',
    'initials': 'Initialen',
    'iseu': 'Onderdeel van de EU',
    'ismanagementallowed': 'Management-gebruiker',
    'isadviceallowed': 'Advies-gebruiker',
    'isadviceadministrator': 'Advies-beheerder',
    'iscrmallowed': 'CRM-gebruiker',
    'iscrmadministrator': 'CRM-beheerder',
    'iscomplianceadministrator': 'Compliance-beheerder',
    'iscomplianceallowed': 'Compliance-gebruiker',
    'ismanagementadministrator': 'Management-beheerder',
    'istimeregistration': 'Heeft urenregistratie',
    'intrastat': 'Intrastat',
    'invoiceNumber': 'Factuurnummer',
    'invoice': 'Factuur',
    'invoicing': 'Facturatie',
    'language': 'Taal',
    'lastname': 'Achternaam',
    'logo': 'Logo',
    'low': 'Laag',
    'ledgernumber': 'Nummer',
    'manager': 'Beheerder',
    'memo': 'Memo',
    'memodvs': 'Memo Dvs',
    'month': 'Maand|Maanden',
    'middlename': 'Tussenvoegsel',
    'missingrate': 'Ontbrekend tarief',
    'sortBy': 'Sorteren op',
    'name': 'Naam',
    'nameofimportfile': 'Importbestand',
    'needspermit': 'Bescheinigung nodig',
    'newpassword': 'Nieuw wachtwoord',
    'no': 'Nee',
    'number': 'Nummer',
    'numberextra': 'Toevoeging',
    'nameoninvoice': 'Naam op factuur',
    'operatingarea': 'Werkgebied',
    'other': 'Overig',
    'password': 'Wachtwoord',
    'passwordRequirements': 'Het wachtwoord dient minimaal 6 tekens lang te zijn en minimaal: 1 hoofdletter, 1 kleine letter, 1 getal en een speciaal teken (b.v. , . _ & ? etc) te bevatten',
    'period': 'Periode',
    'permit': 'Bescheinigung',
    'percentage': 'Percentage',
    'phone': 'Telefoon',
    'possiblePeriods': 'Mogelijke periodes',
    'pricetransactions': 'Tarief transacties',
    'pricenil': 'Tarief nihil',
    'pricetransactionsyear': 'Tarief transacties JV',
    'pricenilyear': 'Tarief nihil JV',
    'pricepercentage': 'Tarief percentage',
    'pricemaximum': 'Tarief maximum',
    'profitmotive': 'Winstoogmerk',
    'postaladdress': 'Postadres',
    'postalcode': 'Postcode',
    'postcode': 'Postcode',
    'quantity': 'Aantal stuks',
    'recipientEmail': 'E-mail afzender',
    'recipientName': 'Naam afzender',
    'recordcount': 'Aantal regels',
    'relativediffirence': 'Relatief verschil',
    'reopen': 'Openzetten',
    'remarks': 'Opmerkingen',
    'reminder': 'Herinnering',
    'reminderEmailAddresses': 'E-mailadressen reminders',
    'revenueB2CLongDistance': 'Omzet afstandsverkopen (B2C)',
    'revenueB2CInternal': 'RevenueB2CInternal',
    'revenueB2BLongDistance': 'RevenueB2BLongDistance',
    'revenueB2BInternal': 'RevenueB2BInternal',
    'repeatinginvoice': 'Terugkerende factuurregels',
    'repeatingperiod': 'Herhalende periode',
    'roundRevenue': 'Omzet afronden',
    'roundVat': 'BTW afronden',
    'threshold': 'Drempel',
    'remindertype': 'Soort',
    'sendEmailsTo': 'Stuur berichten aan',
    'sent': 'Verzonden',
    'sendbyemail': 'Per e-mail',
    'sendbypost': 'Per post',
    'sendinvoiceby': 'Factuur verzenden per',
    'sendinvoiceto': 'Factuur verzenden naar',
    'sendinvoicetoothercompany': 'Factuur verzenden naar ander bedrijf',
    'sellername': 'Naam verkoper',
    'settings': 'Instellingen',
    'sharepoint': 'SharePoint',
    'shortname': 'Afkorting',
    'showdebit': 'Debet en credit splitsen',
    'showinforecast': 'Show in prognose',
    'skipthreshold': 'Geen drempel',
    'startdate': 'Startdatum',
    'startdateShort': 'Start',
    'standard': 'standaard',
    'subject': 'Onderwerp',
    'submitted': 'Ingediend',
    'symbol': 'Symbool',
    'tax': 'BTW',
    'taxrate': 'BTW-tarief',
    'taxType': 'Soort belasting',
    'taxTypeManager': 'Indiener',
    'taxbankname': 'Bank belastingdienst',
    'taxiban': 'IBAN belastingdienst',
    'taxbic': 'BIC belastingdienst',
    'taxaccountname': 'Rekeninghouder belastingdienst',
    'taxoffice': 'Belastingdienst',
    'tag': 'Tag',
    'template': 'Sjabloon',
    'team': 'Team',
    'teams': 'Teams',
    'teammember': 'Teamlid',
    'teammembers': 'Teamleden',
    'theoreticalturnover': 'Theoretische omzet',
    'thresholdInEuro': 'Drempelbedrag in euros',
    'thresholdPassedOn': 'Datum',
    'timestatus': 'Status',
    'timezone': 'Tijdzone',
    'timeregistration': 'Tijdregistratie',
    'transactioncount': 'Aantal transacties',
    'total': 'Totaal',
    'translations': 'Vertalingen',
    'type': 'Soort',
    'typeofinvoice': 'Type facturatie',
    'to': 'Aan',
    'totalperiod': 'Totale periode',
    'underlyingData': 'Onderliggende gegevens',
    'upto': 't/m',
    'uniqueid': 'UniekeID',
    'usertime': 'Weekstaten',
    'user': 'Gebruiker',
    'vatisvalid': 'BTW nr geldig',
    'vatisvalidstatus': {
        'valid': 'Ja',
        'invalid': 'Nee'
    },
    'via': 'Via',
    'visitingaddress': 'Bezoekadres',
    'vat': 'OB',
    'vatcode': 'Btw code',
    'vatrefund': 'Teruggaaf OB',
    'vatthreshold': 'Drempelbedrag',
    'vatthresholddistancesales': 'BTW drempel afstandsverkopen',
    'vathighoriginal': 'BTW hoog (orig)',
    'vathigheuro': 'BTW hoog (euro)',
    'vatloworiginal': 'BTW laag (orig)',
    'vatloweuro': 'BTW laag (euro)',
    'vatregime': 'BTW tarief',
    'value': 'Waarde',
    'valueinclvat': 'Waarde incl. btw',
    'valueexclvat': 'Waarde excl. btw',
    'vatnumber': 'Btw-nummer',
    'website': 'Website',
    'worktimefactor': 'Werktijdfactor',
    'year': 'Jaar',
    'yearDeclaration': 'Jaarverklaring',
    'yes': 'Ja',
    'declarationproposalisclosed': 'Dit declaratievoorstel is afgerond',
    'weekisclosed': 'Deze weekstaat is gesloten',
    'worktype': 'Werksoort',
    'worktypes': 'Werksoorten',
    'week': 'Week',
    'wholetotal': 'Algeheel totaal',
    'DeclarationType': {
        'CustomerListing': 'Klantenlisting',
        'Icp': 'ICP',
        'Intrastat': 'Intrastat',
        'Vat': 'OB'
    },
    'ExciseType': {
        'Excise': 'Accijns'
    },
    'RefundType': {
        'VatRefund': 'Teruggaaf OB'
    },
    'productivitypercentage': 'Productiviteitspercentage',
    'year': 'Jaar',
    'numberofworkweeks': 'Aantal werkweken',
    'inefficiencypercentage': 'Inefficiëntie',
    'adviceshare': 'Verdeling advies',
    'complianceshare': 'Verdeling compliance',
    'workingdays': 'Werkdagen',
    'turnoverforecastsettings': 'Instellingen voor omzetvoorspelling',
    'yearlyforecast': 'Jaarlijkse prognose',
    'monthlyforecast': 'Maandelijkse prognose',
    'productivehourperweek': 'Productieve uren per week',
    'productivehourperyear': 'Productieve uren per jaar',
    'houlyrateofuserperteam': 'Uurtarief gebruiker per team',
    'forcastedturnover': 'Verwachte omzet',
    'inefficiency': 'Inefficiëntie',
    'realforecastedturnover': 'Reële verwachte omzet',
    'teamname': 'Teamnaam',
    'monthview': 'Maandweergave',
    'nationaltotal': 'Totaal nationaal',
    'internationaltotal': 'Totaal internationaal',
    'advicetotal': 'Totaal advies',
    'compliancetotal': 'Totaal compliance',
    'yearlyturnoverperteam': 'Jaarlijkse omzet per team',
    'undeclaredhours': 'Niet-declarabele uren',

};

export default nl;