import Select2 from 'v-select2-component';

export default {
    name:'SelectTaxDescription',
    components: {
        'select2': Select2
    },
    props: {
        value: Number,
        taxtype: {
            type:Number,
            default: 0
        },
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type:String,
            default: null
        }
    },
    data() {
        return {
            declarationTypes: [
                { id: 0, text: this.$t('vat') },
                { id: 1, text: this.$t('intrastat') },
                { id: 2, text: this.$t('icp') },
                { id: 3, text: this.$t('customerlisting') },
                { id: 4, text: this.$t('other') },
            ],
            refundTypes: [
                { id: 0, text: this.$t('vatrefund') },
                { id: 1, text: this.$t('other') }
            ],
            exciseTypes: [
                { id: 0, text: this.$t('excise') },
                { id: 1, text: this.$t('other') }
            ],
        };
    },
    computed: {
        options() {
            switch(this.taxtype) {
            case 0:
                return this.declarationTypes;
            case 1:
                return this.refundTypes;
            case 2:
                return this.exciseTypes;
            }
            return [];
        }
    },
};