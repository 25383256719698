
let settings = [
    {
        name: "countries",
        title: "countries",
        params: {},
        seqno: 1
    },
    {
        name: "currencies",
        title: "currencies",
        params: {},
        seqno: 2
    },
    {
        name: "emailtemplates",
        title: "emailtemplates",
        params: {
            type: 1
        },
        seqno: 3
    }
];
let reports = [
    {
        name: "reportDeclarations",
        title: 'declarations',
        params: {},
        seqno: 1
    },
    {
        name: "reportRefunds",
        title: 'refunds',
        params: {},
        seqno: 2
    },
    {
        name: "reportPermits",
        title: 'permits',
        params: {},
        seqno: 3
    },
    {
        name: "reportExcises",
        title: 'excise',
        params: {},
        seqno: 4
    },
    {
        name: "reportReminderLog",
        title: 'reminderlog',
        params: {},
        seqno: 5
    },
    {
        name: "reportInvoice",
        title: 'invoice',
        params: {},
        seqno: 6
    },

];
let companies = [
    {
        name: "companies",
        params: {},
        title: 'overview',
        seqno: 1
    },
    {
        name: "companyfilter",
        params: {},
        title: 'selections',
        seqno: 2
    },
];
export default {
    settings: settings,
    companies: companies,
    reports: reports
}