import Select2 from 'v-select2-component';
import range from 'lodash.range';
import moment from 'moment';

export default {
    components: {
        'select2': Select2
    },
    name: 'SelectYear',
    props: {
        value: Number,
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type:String,
            default: null
        }
    },
    data() {
        return {
            years:range(2016, moment().year()+2)
        };
    },
    computed: {
        options() {
            return this.years.map(x => {
                return {id:x, text: x};
            });
        },
        id() {
            return Date.now();
        }
    },
};