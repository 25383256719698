import * as actionList from '../actions/companyonestopstoplistactions';
import moment from 'moment';

const state = {
    search: '',
    page: 0,
    startDate: moment().startOf('year').toDate(),
    endDate: moment().endOf('year').toDate(),    
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startDate: state => state.startDate,
    endDate: state => state.endDate,     
};

const actions = {
    [actionList.COMPANYONESTOPSTOPLIST_SEARCH]: ({ commit }, search) => {
        commit(actionList.COMPANYONESTOPSTOPLIST_SEARCH, search);
    },
    [actionList.COMPANYONESTOPSTOPLIST_PAGE]: ({ commit }, page) => {
        commit(actionList.COMPANYONESTOPSTOPLIST_PAGE, page);
    },
    [actionList.COMPANYONESTOPSTOPLIST_STARTDATE]: ({ commit }, startDate) => {
        commit(actionList.COMPANYONESTOPSTOPLIST_STARTDATE, startDate);
    },
    [actionList.COMPANYONESTOPSTOPLIST_ENDDATE]: ({ commit }, endDate) => {
        commit(actionList.COMPANYONESTOPSTOPLIST_ENDDATE, endDate);
    },     
};

const mutations = {
    [actionList.COMPANYONESTOPSTOPLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionList.COMPANYONESTOPSTOPLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionList.COMPANYONESTOPSTOPLIST_STARTDATE]: (state, startDate) => {
        state.page = 0;
        state.startDate = startDate;
    },
    [actionList.COMPANYONESTOPSTOPLIST_ENDDATE]: (state, endDate) => {
        state.page = 0;
        state.endDate = endDate;
    },
   
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};