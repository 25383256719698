import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import companylist from './modules/companylist';
import countrylist from './modules/countrylist';
import currencylist from './modules/currencylist';
import grouplist from './modules/grouplist';
import languagelist from './modules/languagelist';
import userlist from './modules/userlist';
import companymemolist from './modules/companymemolist';
import companyreminderloglist from './modules/companyreminderloglist';
import reportdeclarations from './modules/reportdeclarations';
import reportrefunds from './modules/reportrefunds';
import reportexcises from './modules/reportexcises';
import reportpermits from './modules/reportpermits';
import importfilelist from './modules/importfilelist';
import companytransactionlist from './modules/companytransactionlist';
import companytaxdatalist from './modules/companytaxdatalist';
import companypurchaselist from './modules/companypurchaselist';
import companytaxoverviewlist from './modules/companytaxoverviewlist';
import checkvatlist from './modules/checkvatlist';
import vatcodelist from './modules/vatcodelist';
import branchassociationlist from './modules/branchassociationlist';
import corporatesectorlist from './modules/corporatesectorlist';
import taglist from './modules/taglist';
import worktypelist from './modules/worktypelist';
import worktypegrouplist from './modules/worktypegrouplist';
import menumodule from './modules/menumodule';
import companygroupbycompanylist from './modules/companygroupbycompanylist';
import companycorrespondencelist from './modules/companycorrespondencelist';
import companyfolderlist from './modules/companyfolderlist';
import timeregistrationlist from './modules/timeregistrationlist';
import usertimelist from './modules/usertimelist';
import declareproposallist from './modules/declareproposallist';
import companydeclareproposallist from './modules/companydeclareproposallist';
import conceptlist from './modules/conceptlist';
import sendtolist from './modules/sendtolist';
import sentlist from './modules/sentlist';
import reportinvoice from './modules/reportinvoice';
import teamlist from './modules/teamlist';
import teammemberlist from './modules/teammemberlist';
import turnoverforecastsettingslist from './modules/turnoverforecastsettingslist';
import reportyearlyforecastlist from './modules/reportyearlyforecastlist';
import reportmonthlyforecastlist from './modules/reportmonthlyforecastlist';
import reportyearlyturnoverperteamlist from './modules/reportyearlyturnoverperteamlist';
import reportregisteredtimelist from './modules/reportregisteredtimelist';
import reportcompareperiodlist from './modules/reportcompareperiodlist';
import reportmissingratelist from './modules/reportmissingratelist';
import reportundeclaredhourslist from './modules/reportundeclaredhourslist';
import companytaxoverviewaddonlist from './modules/companytaxoverviewaddonlist';
import companyonestopstoplist from './modules/companyonestopstoplist';
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        auth,
        companylist,
        countrylist,
        languagelist,
        currencylist,
        userlist,
        companymemolist,
        companyreminderloglist,
        reportdeclarations,
        reportrefunds,
        reportexcises,
        reportpermits,
        importfilelist,
        companytransactionlist,
        companytaxdatalist,
        companytaxoverviewlist,
        grouplist,
        checkvatlist,
        companypurchaselist,
        vatcodelist,
        branchassociationlist,
        corporatesectorlist,
        taglist,
        worktypelist,
        worktypegrouplist,
        menumodule,
        companygroupbycompanylist,
        companycorrespondencelist,
        companyfolderlist,
        timeregistrationlist,
        usertimelist,
        declareproposallist,
        companydeclareproposallist,
        conceptlist,
        sendtolist,
        sentlist,
        reportinvoice,
        teamlist,
        teammemberlist,
        turnoverforecastsettingslist,
        reportyearlyforecastlist,
        reportmonthlyforecastlist,
        reportyearlyturnoverperteamlist,
        reportregisteredtimelist,
        reportcompareperiodlist,
        reportmissingratelist,
        reportundeclaredhourslist,
        companytaxoverviewaddonlist,
        companyonestopstoplist
    },
    strict: debug,
});