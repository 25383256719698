import * as actionList from '../actions/companytaxoverviewaddonlistactions';
import moment from 'moment';

const state = {    
    taxTypeId: null,
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(), 
};

const getters = {    
    startDate: state => state.startDate,
    endDate: state => state.endDate,    
    taxTypeId: state => state.taxTypeId,    
};

const actions = {
    [actionList.COMPANYTAXOVERVIEWADDONLIST_TAXTYPEID]: ({ commit }, taxTypeId) => {
        commit(actionList.COMPANYTAXOVERVIEWADDONLIST_TAXTYPEID, taxTypeId);
    },
    [actionList.COMPANYTAXOVERVIEWADDONLIST_STARTDATE]: ({ commit }, startDate) => {
        commit(actionList.COMPANYTAXOVERVIEWADDONLIST_STARTDATE, startDate);
    },    
    [actionList.COMPANYTAXOVERVIEWADDONLIST_ENDDATE]: ({ commit }, endDate) => {
        commit(actionList.COMPANYTAXOVERVIEWADDONLIST_ENDDATE, endDate);
    },           
};

const mutations = {
    [actionList.COMPANYTAXOVERVIEWADDONLIST_TAXTYPEID]: (state, taxTypeId) => {
        state.taxTypeId = taxTypeId;
    },
    [actionList.COMPANYTAXOVERVIEWADDONLIST_STARTDATE]: (state, startDate) => {
        state.startDate = startDate;        
    },   
    [actionList.COMPANYTAXOVERVIEWADDONLIST_ENDDATE]: (state, endDate) => {
        state.endDate = endDate;
    },             
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};