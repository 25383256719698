import { COMPANYLIST_SEARCH, COMPANYLIST_PAGE, COMPANYLIST_COMPANYTYPE, COMPANYLIST_VIACOMPANYID } from '../actions/companylistactions';

const state = { search:'', page:0, companyType:null,viaCompanyId:null };

const getters = {
    page: state => state.page,
    search: state => state.search,
    companyType: state => state.companyType,
    viaCompanyId:state=>state.viaCompanyId
};

const actions = {
    [COMPANYLIST_SEARCH]: ({ commit }, search) => {
        commit(COMPANYLIST_SEARCH, search);
    },
    [COMPANYLIST_PAGE]: ({ commit }, page) => {
        commit(COMPANYLIST_PAGE, page);
    },
    [COMPANYLIST_COMPANYTYPE]: ({ commit }, companyType) => {
        commit(COMPANYLIST_COMPANYTYPE, companyType);
    },
    [COMPANYLIST_VIACOMPANYID]: ({ commit }, viaCompanyId) => {
        commit(COMPANYLIST_VIACOMPANYID, viaCompanyId);
    },

};

const mutations = {
    [COMPANYLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [COMPANYLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [COMPANYLIST_COMPANYTYPE]: (state, companyType) => {
        state.page = 0;
        state.companyType = companyType;
    },
    [COMPANYLIST_VIACOMPANYID]: (state, viaCompanyId) => {
        state.page = 0;
        state.viaCompanyId = viaCompanyId;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};