import * as actionList from '../actions/companytaxdatalistactions';
import moment from 'moment';

const state = {
    page: 0,
    startDate: moment().startOf('year').toDate(),
    endDate: moment().endOf('year').toDate(),
    departureCountryId: null,
    arrivalCountryId: null,
    vatCountryId: null,
    transactionType: null,
    buyerType: null,
    deliveryCondition:null,
    vatIsValid: null
};

const getters = {
    page: state => state.page,
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    departureCountryId: state => state.departureCountryId,
    arrivalCountryId: state => state.arrivalCountryId,
    vatCountryId: state => state.vatCountryId,
    transactionType: state => state.transactionType,
    buyerType: state => state.buyerType,
    deliveryCondition: state => state.deliveryCondition,   
    vatIsValid: state => state.vatIsValid,    
};

const actions = {
    [actionList.COMPANYTAXDATALIST_PAGE]: ({ commit }, page) => {
        commit(actionList.COMPANYTAXDATALIST_PAGE, page);
    },
    [actionList.COMPANYTAXDATALIST_STARTDATE]: ({ commit }, startDate) => {
        commit(actionList.COMPANYTAXDATALIST_STARTDATE, startDate);
    },
    [actionList.COMPANYTAXDATALIST_ENDDATE]: ({ commit }, endDate) => {
        commit(actionList.COMPANYTAXDATALIST_ENDDATE, endDate);
    },
    [actionList.COMPANYTAXDATALIST_DEPARTURECOUNTRYID]: ({ commit }, departureCountryId) => {
        commit(actionList.COMPANYTAXDATALIST_DEPARTURECOUNTRYID, departureCountryId);
    },
    [actionList.COMPANYTAXDATALIST_ARRIVALCOUNTRYID]: ({ commit }, arrivalCountryId) => {
        commit(actionList.COMPANYTAXDATALIST_ARRIVALCOUNTRYID, arrivalCountryId);
    },
    [actionList.COMPANYTAXDATALIST_VATCOUNTRYID]: ({ commit }, vatCountryId) => {
        commit(actionList.COMPANYTAXDATALIST_VATCOUNTRYID, vatCountryId);
    },
    [actionList.COMPANYTAXDATALIST_TRANSACTIONTYPE]: ({ commit }, transactionType) => {
        commit(actionList.COMPANYTAXDATALIST_TRANSACTIONTYPE, transactionType);
    },
    [actionList.COMPANYTAXDATALIST_BUYERTYPE]: ({ commit }, buyerType) => {
        commit(actionList.COMPANYTAXDATALIST_BUYERTYPE, buyerType);
    },
    [actionList.COMPANYTAXDATALIST_DELIVERYCONDITION]: ({ commit }, deliveryCondition) => {
        commit(actionList.COMPANYTAXDATALIST_DELIVERYCONDITION, deliveryCondition);
    },
    [actionList.COMPANYTAXDATALLIST_VATISVALID]: ({ commit }, vatIsValid) => {
        commit(actionList.COMPANYTAXDATALLIST_VATISVALID, vatIsValid);
    }   
};

const mutations = {
    [actionList.COMPANYTAXDATALIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionList.COMPANYTAXDATALIST_STARTDATE]: (state, startDate) => {
        state.page = 0;
        state.startDate = startDate;
    },
    [actionList.COMPANYTAXDATALIST_ENDDATE]: (state, endDate) => {
        state.page = 0;
        state.endDate = endDate;
    },
    [actionList.COMPANYTAXDATALIST_DEPARTURECOUNTRYID]: (state, departureCountryId) => {
        state.page = 0;
        state.departureCountryId = departureCountryId;
    },
    [actionList.COMPANYTAXDATALIST_ARRIVALCOUNTRYID]: (state, arrivalCountryId) => {
        state.page = 0;
        state.arrivalCountryId = arrivalCountryId;
    },
    [actionList.COMPANYTAXDATALIST_VATCOUNTRYID]: (state, vatCountryId) => {
        state.page = 0;
        state.vatCountryId = vatCountryId;
    },    
    [actionList.COMPANYTAXDATALIST_TRANSACTIONTYPE]: (state, transactionType) => {
        state.page = 0;
        state.transactionType = transactionType;
    },
    [actionList.COMPANYTAXDATALIST_BUYERTYPE]: (state, buyerType) => {
        state.page = 0;
        state.buyerType = buyerType;
    },
    [actionList.COMPANYTAXDATALIST_DELIVERYCONDITION]: (state, deliveryCondition) => {
        state.page = 0;
        state.deliveryCondition = deliveryCondition;
    },
    [actionList.COMPANYTAXDATALLIST_VATISVALID]: (state, vatIsValid) => {
        state.page = 0;
        state.vatIsValid = vatIsValid;
    },    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};