<template>
    <div :class="className"></div>
</template>

<style scoped>
    .vue-echarts {
        width: 100%;
        height: 100%;
    }
</style>

<script>
    import * as ECharts from 'echarts/lib/echarts'
    import 'echarts/lib/chart/bar';
    import 'echarts/lib/chart/line';
    import 'echarts/lib/chart/pie';
    import 'echarts/lib/component/legend'
    import 'echarts/lib/component/dataZoom'
    import 'echarts/lib/component/tooltip'

    import Debounce from 'lodash.debounce';
    import Resize from 'element-resize-detector';
    import Wrapper from './ChartWrapper.js';

    const IEcharts = Wrapper(ECharts, Resize, Debounce);
    export default IEcharts;
</script>
