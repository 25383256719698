export default {
    name:'StickyMenu',
    props: {
        title: {
            type: String,
            default: ''
        },
        inline: {
            type: Boolean,
            default: false
        }
    }
};