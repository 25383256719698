export default {
    name: 'FormGroup',
    props: {
        label: String,
        labelclass: {
            type: String,
            default: ''
        },
        groupclass: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
        };
    },
};