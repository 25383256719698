import { DECLAREPROPOSALLIST_SEARCH, DECLAREPROPOSALLIST_PAGE, DECLAREPROPOSALLIST_MANAGER } from '../actions/declareproposallistactions';

const state = { search: '', page: 0, manager: 0 };

const getters = {
    page: state => state.page,
    search: state => state.search,
    manager: state => state.manager
};

const actions = {
    [DECLAREPROPOSALLIST_SEARCH]: ({ commit }, search) => {
        commit(DECLAREPROPOSALLIST_SEARCH, search);
    },
    [DECLAREPROPOSALLIST_PAGE]: ({ commit }, page) => {
        commit(DECLAREPROPOSALLIST_PAGE, page);
    },
    [DECLAREPROPOSALLIST_MANAGER]: ({ commit }, manager) => {

        commit(DECLAREPROPOSALLIST_MANAGER, manager);
        
    }
};
const mutations = {
    [DECLAREPROPOSALLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [DECLAREPROPOSALLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [DECLAREPROPOSALLIST_MANAGER]: (state, manager) => {        
        state.manager = manager;
        state.page = 0;
        
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};