var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("navmenu", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isAuthenticated,
            expression: "isAuthenticated"
          }
        ]
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAuthenticated,
              expression: "isAuthenticated"
            }
          ],
          staticClass: "br-header"
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTestEnvironment,
                  expression: "isTestEnvironment"
                }
              ],
              staticClass: "br-header-left"
            },
            [_c("h2", [_vm._v(_vm._s(_vm.$t("title.testenvironment")))])]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "br-header-left" }, [
            _vm.isAdministrator || _vm.isCRM
              ? _c("div", { staticClass: "wd-100" }, [
                  _c("input", {
                    staticClass:
                      "btn btn-sm btn-block btn-secondary tx-uppercase",
                    class: _vm.currentmodule == "CRM" ? "bg-success" : "",
                    attrs: { type: "button", value: _vm.$t("module.crm") },
                    on: {
                      click: function($event) {
                        return _vm.goToModule("CRM")
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isAdministrator || _vm.isCompliance
              ? _c("div", { staticClass: "wd-100 mg-l-15" }, [
                  _c("input", {
                    staticClass:
                      "btn btn-sm btn-block btn-secondary tx-uppercase",
                    class:
                      _vm.currentmodule == "COMPLIANCE" ? "bg-success" : "",
                    attrs: {
                      type: "button",
                      value: _vm.$t("module.compliance")
                    },
                    on: {
                      click: function($event) {
                        return _vm.goToModule("COMPLIANCE")
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isAdministrator || _vm.isAdvice
              ? _c("div", { staticClass: "wd-100 mg-l-15" }, [
                  _c("input", {
                    staticClass:
                      "btn btn-sm btn-block btn-secondary tx-uppercase",
                    class: _vm.currentmodule == "ADVICE" ? "bg-success" : "",
                    attrs: {
                      type: "button",
                      value: _vm.$t("module.advice"),
                      disabled: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.goToModule("ADVICE")
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isAdministrator || _vm.isManagement
              ? _c("div", { staticClass: "wd-100 mg-l-15" }, [
                  _c("input", {
                    staticClass:
                      "btn btn-sm btn-block btn-secondary tx-uppercase",
                    class:
                      _vm.currentmodule == "MANAGEMENT" ? "bg-success" : "",
                    attrs: {
                      type: "button",
                      value: _vm.$t("module.management")
                    },
                    on: {
                      click: function($event) {
                        return _vm.goToModule("MANAGEMENT")
                      }
                    }
                  })
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "br-header-right" }, [
            _c("nav", { staticClass: "nav" }, [
              _c("div", { staticClass: "dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link nav-link-profile",
                    attrs: {
                      href: "",
                      "data-toggle": "dropdown",
                      "aria-expanded": "false"
                    }
                  },
                  [
                    _c("span", { staticClass: "logged-name hidden-md-down" }, [
                      _vm._v(_vm._s(_vm.username))
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "wd-32 rounded-circle",
                      attrs: {
                        src: "https://via.placeholder.com/64x64",
                        alt: ""
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "square-10 bg-success" })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-menu dropdown-menu-header wd-200",
                    staticStyle: {
                      position: "absolute",
                      transform: "translate3d(-18px, 48px, 0px)",
                      top: "0px",
                      left: "0px",
                      "will-change": "transform"
                    },
                    attrs: { "x-placement": "bottom-start" }
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "list-unstyled user-profile-nav" },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              { attrs: { to: { name: "editprofile" } } },
                              [
                                _c("i", { staticClass: "icon ion-ios-person" }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("title.editprofile"))
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            { attrs: { href: "#" }, on: { click: _vm.logout } },
                            [
                              _c("i", { staticClass: "icon ion-ios-power" }),
                              _vm._v(" " + _vm._s(_vm.$t("actions.logout")))
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                )
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: { "br-mainpanel": _vm.isAuthenticated } },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c("vuedal")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "br-header-left" }, [
      _c("div", { staticClass: "navicon-left d-none d-lg-flex" }, [
        _c("a", { attrs: { id: "btnLeftMenu", href: "" } }, [
          _c("i", { staticClass: "ion ion-ios-menu" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "navicon-left d-flex d-lg-none" }, [
        _c("a", { attrs: { id: "btnLeftMenuMobile", href: "" } }, [
          _c("i", { staticClass: "ion ion-ios-menu" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }