export default {
    name: 'Paging',
    props: {
        currentPage: Number,
        totalPages: Number,
        surroundingPageLinkSize: {
            type:Number,
            default: 3
        }
    },
    data() {
        return {
        };
    },
    methods: {
        setCurrentPage(page) {
            this.$emit('page-changed', page);
        }
    },
    computed: {
        pages() {
            let buttons = [];
            let truncate = this.totalPages > (7 + (this.surroundingPageLinkSize * 2));
            if (!truncate) {
                for (let i = 0; i < this.totalPages; i++) {
                    buttons.push({value:i,text:i+1});
                }
            }
            else {
                var truncateEnd = this.currentPage < (1 + (this.surroundingPageLinkSize * 2)),
                    truncateBoth = (this.totalPages - (this.surroundingPageLinkSize * 2)) > this.currentPage && this.currentPage > (this.surroundingPageLinkSize * 2);
            
                if (truncateEnd) {
                    for (let j = 0; j < (4 + (this.surroundingPageLinkSize * 2)); j++) {
                        buttons.push({value:j,text:j+1});
                    }
                    buttons.push({value:null,text:'...'});
                    buttons.push({value:this.totalPages - 2,text:this.totalPages - 1});
                    buttons.push({value:this.totalPages - 1,text:this.totalPages });
                }
                else if (truncateBoth) {
                    buttons.push({value:0,text:'1'});
                    buttons.push({value:1,text:'2'});
                    buttons.push({value:null,text:'...'});

                    for (let k = (this.currentPage - this.surroundingPageLinkSize); k <= (this.currentPage + this.surroundingPageLinkSize); k++) {
                        buttons.push({value:k,text:k+1});
                    }
                    buttons.push({value:null,text:'...'});
                    buttons.push({value:this.totalPages - 2,text:this.totalPages - 1});
                    buttons.push({value:this.totalPages - 1,text:this.totalPages });
                }
                else { // Truncate start
                    buttons.push({value:0,text:'1'});
                    buttons.push({value:1,text:'2'});
                    buttons.push({value:null,text:'...'});
                    for (let l = this.totalPages - (2 + (this.surroundingPageLinkSize * 2)); l < this.totalPages; l++) {
                        buttons.push({value:l,text:l+1});
                    }
                }
            }
            return buttons;
        }
    }
};
