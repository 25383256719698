import { render, staticRenderFns } from "./buttondelete.vue?vue&type=template&id=3f609e07&"
import script from "./buttondelete.js?vue&type=script&lang=js&"
export * from "./buttondelete.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projecten\\EigenSteil\\Employee Portal\\Frontend\\EmployeePortal.Portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f609e07')) {
      api.createRecord('3f609e07', component.options)
    } else {
      api.reload('3f609e07', component.options)
    }
    module.hot.accept("./buttondelete.vue?vue&type=template&id=3f609e07&", function () {
      api.rerender('3f609e07', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/shared/common/buttons/buttondelete.vue"
export default component.exports