import axios from 'axios';
import querystring from 'querystring';
import { AUTH_REQUEST, AUTH_REQUESTEXTRA, AUTH_SUCCESSEXTRA, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, SET_MODULE } from '../actions/authactions';

const state = {
    token: localStorage.getItem('user-token') || '',
    status: '',
    isAdministrator: localStorage.getItem('user-is-admin') === 'true',
    username: localStorage.getItem('user-name'),
    testenvironment: true,
    isCRM: localStorage.getItem('isCRM') === 'true',
    isCompliance: localStorage.getItem('isCompliance') === 'true',
    isTimeRegistration: localStorage.getItem('isTimeRegistration') === 'true',
    isAdvice: false,
    isManagement: false,
    currentmodule: localStorage.getItem('currentmodule'),
    userid: localStorage.getItem('userid'),
    isCRMAdministrator: localStorage.getItem('isCRMAdministrator') === 'true',
};

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    isAdministrator: state => state.isAdministrator,
    username: state => state.username,
    testenvironment: state => state.testenvironment,
    isCRM: state => state.isCRM,
    isCompliance: state => state.isCompliance,
    isAdvice: state => state.isAdvice,
    isManagement: state => state.isManagement,
    isTimeRegistration: state => state.isTimeRegistration,
    currentmodule: state => state.currentmodule,
    userid: state => state.userid,
    isCRMAdministrator: state => state.isCRMAdministrator,
    token: state => state.token,
};

const actions = {
    [AUTH_REQUEST]: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            axios.post(APIURL + '/connect/token', querystring.stringify({ grant_type: 'password', username: user.username, password: user.password, scope: 'roles' }))
                .then(resp => {
                    localStorage.setItem('user-token', resp.data.access_token);
                    // Here set the header of your ajax library to the token value.
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.access_token;
                    commit(AUTH_SUCCESS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    commit(AUTH_ERROR, err);
                    localStorage.removeItem('user-token');
                    reject(err);
                });
        });
    },
    [AUTH_REQUESTEXTRA]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUESTEXTRA);
            axios.get(APIURL + '/user/me')
                .then(resp => {
                    localStorage.setItem('user-is-admin', resp.data.isAdministrator);
                    localStorage.setItem('user-name', resp.data.username);
                    localStorage.setItem('userid', resp.data.userid);
                    commit(AUTH_SUCCESSEXTRA, resp);
                    resolve(resp);
                })
                .catch(err => {
                    commit(AUTH_ERROR, err);
                    localStorage.removeItem('user-token');
                    reject(err);
                });
        });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise((resolve) => {
            commit(AUTH_LOGOUT);
            localStorage.removeItem('user-token');
            localStorage.removeItem('user-is-admin');
            localStorage.removeItem('user-name');
            localStorage.removeItem('isCRM');
            localStorage.removeItem('isCompliance');
            localStorage.removeItem('isTimeRegistration');
            localStorage.removeItem('currentmodule');
            localStorage.removeItem('userid');
            localStorage.removeItem('isCRMAdministrator');
            // remove the axios default header
            delete axios.defaults.headers.common['Authorization'];
            resolve();
        });
    },
    [SET_MODULE]: ({ commit }, modulename) => {
        commit(SET_MODULE, modulename);
    }
};

const mutations = {
    [AUTH_REQUEST]: (state) => {
        state.status = 'loading';
    },
    [AUTH_REQUESTEXTRA]: (state) => {
        state.status = 'loading';
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.token = resp.data.access_token;
    },
    [AUTH_SUCCESSEXTRA]: (state, resp) => {
        state.status = 'success';
        state.isAdministrator = resp.data.isAdministrator;
        state.username = resp.data.username;
        state.testenvironment = resp.data.testenvironment;
        state.isCRM = resp.data.isCRM;
        state.isCompliance = resp.data.isCompliance;
        state.isAdvice = resp.data.isAdvice;
        state.isManagement = resp.data.isManagement;
        state.isTimeRegistration = resp.data.isTimeRegistration;
        state.isCRMAdministrator = resp.data.isTimeRegistration;
        state.currentmodule = resp.data.currentmodule;
        state.userid = resp.data.userid;
        localStorage.setItem('isCRM', state.isCRM);
        localStorage.setItem('isCompliance', state.isCompliance);
        localStorage.setItem('currentmodule', state.currentmodule);
        localStorage.setItem('isTimeRegistration', state.isTimeRegistration);
        localStorage.setItem('isCRMAdministrator', state.isCRMAdministrator);
    },
    [AUTH_ERROR]: (state) => {
        state.status = 'error';
        state.username = '';
    },
    [AUTH_LOGOUT]: (state) => {
        state.token = '';
        state.isAdministrator = false;
        state.username = '';
        state.userid = null;
    },
    [SET_MODULE]: (state, resp) => {
        state.currentmodule = resp;
        localStorage.setItem('currentmodule', state.currentmodule);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};