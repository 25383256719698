import axios from 'axios';
import Select2 from 'v-select2-component';

export default {
    components: {
        'select2': Select2
    },
    name: 'SelectLanguage',
    props: {
        value: Number,
        inputclass: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            languages:[]
        };
    },
    computed: {
        options() {
            return this.languages.map(x => {
                return {id:x.id, text: x.description};
            });
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            axios.get(APIURL + '/shared/settings/language/list')
                .then(resp => this.languages=resp.data)
                .catch(err => console.log(err));
        }
    }
};