import * as actionList from '../actions/reportyearlyturnoverperteamactions';
import moment from 'moment';
import branchassociation from '../../components/crm/settings/branchassociation/branchassociation';

const state = {
    search: '',
    page: 0,
    year: moment().year(),
    company: null,
    branchassociation: null,
    corporatesector: null,
    oprativearea: null,
    profitmotive: null
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    year: state => state.year,
    company: state => state.company,
    branchassociation: state => state.branchassociation,
    corporatesector: state => state.corporatesector,
    oprativearea: state => state.oprativearea,
    profitmotive: state => state.profitmotive,
};

const actions = {
    [actionList.REPORTYEARLYTURNOVERPERTEAM_SEARCH]: ({ commit }, search) => {
        commit(actionList.REPORTYEARLYTURNOVERPERTEAM_SEARCH, search);
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_PAGE]: ({ commit }, page) => {
        commit(actionList.REPORTYEARLYTURNOVERPERTEAM_PAGE, page);
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_YEAR]: ({ commit }, year) => {
        commit(actionList.REPORTYEARLYTURNOVERPERTEAM_YEAR, year);
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_COMPANY]: ({ commit }, company) => {
        commit(actionList.REPORTYEARLYTURNOVERPERTEAM_COMPANY, company);
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_CORPORATESECTOR]: ({ commit }, corporatesector) => {
        commit(actionList.REPORTYEARLYTURNOVERPERTEAM_CORPORATESECTOR, corporatesector);
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_BRANCHASSOCIATION]: ({ commit }, branchassociation) => {
        commit(actionList.REPORTYEARLYTURNOVERPERTEAM_BRANCHASSOCIATION, branchassociation);
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_OPERATIVEAREA]: ({ commit }, oprativearea) => {
        commit(actionList.REPORTYEARLYTURNOVERPERTEAM_OPERATIVEAREA, oprativearea);
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_PROFITMOTIVE]: ({ commit }, profitmotive) => {
        commit(actionList.REPORTYEARLYTURNOVERPERTEAM_PROFITMOTIVE, profitmotive);
    },
};

const mutations = {
    [actionList.REPORTYEARLYTURNOVERPERTEAM_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_YEAR]: (state, year) => {
        state.page = 0;
        state.year = year;
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_COMPANY]: (state, company) => {
        state.page = 0;
        state.company = company;
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_CORPORATESECTOR]: (state, corporatesector) => {
        state.page = 0;
        state.corporatesector = corporatesector;
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_BRANCHASSOCIATION]: (state, branchassociation) => {
        state.page = 0;
        state.branchassociation = branchassociation;
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_OPERATIVEAREA]: (state, oprativearea) => {
        state.page = 0;
        state.oprativearea = oprativearea;
    },
    [actionList.REPORTYEARLYTURNOVERPERTEAM_PROFITMOTIVE]: (state, profitmotive) => {
        state.page = 0;
        state.profitmotive = profitmotive;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};