import { CONCEPTLIST_SEARCH, CONCEPTLIST_PAGE, CONCEPTLIST_USER } from '../actions/conceptlistactions';

const state = { search: '', page: 0, user: -1, };

const getters = {
    page: state => state.page,
    search: state => state.search,
    user: state => state.user,
};

const actions = {
    [CONCEPTLIST_SEARCH]: ({ commit }, search) => {
        commit(CONCEPTLIST_SEARCH, search);
    },
    [CONCEPTLIST_PAGE]: ({ commit }, page) => {
        commit(CONCEPTLIST_PAGE, page);
    },
    [CONCEPTLIST_USER]: ({ commit }, user) => {
        commit(CONCEPTLIST_USER, user);
    },
};

const mutations = {
    [CONCEPTLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [CONCEPTLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [CONCEPTLIST_USER]: (state, user) => {
        state.page = 0;
        state.user = user;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};