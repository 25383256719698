import * as actionList from '../actions/reportcompareperiodactions';
import moment from 'moment';

const state = {
    
    startDate: moment().subtract(1, 'year').startOf('year').toDate(),
    endDate: moment().subtract(1, 'year').endOf('year').toDate(),
    startDate2: moment().startOf('year').toDate(),
    endDate2: moment().endOf('year').toDate(),
    
};

const getters = {    
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    startDate2: state => state.startDate2,
    endDate2: state => state.endDate2,
};

const actions = {
    
    [actionList.REPORTCOMPAREPERIOD_STARTDATE]: ({ commit }, startDate) => {
        commit(actionList.REPORTCOMPAREPERIOD_STARTDATE, startDate);
    },
    [actionList.REPORTCOMPAREPERIOD_ENDDATE]: ({ commit }, endDate) => {
        commit(actionList.REPORTCOMPAREPERIOD_ENDDATE, endDate);
    },
    [actionList.REPORTCOMPAREPERIOD_STARTDATE2]: ({ commit }, startDate2) => {
        commit(actionList.REPORTCOMPAREPERIOD_STARTDATE2, startDate2);
    },
    [actionList.REPORTCOMPAREPERIOD_ENDDATE2]: ({ commit }, endDate2) => {
        commit(actionList.REPORTCOMPAREPERIOD_ENDDATE2, endDate2);
    },
};

const mutations = {   
    [actionList.REPORTCOMPAREPERIOD_STARTDATE]: (state, startDate) => {        
        state.startDate = startDate;
    },
    [actionList.REPORTCOMPAREPERIOD_ENDDATE]: (state, endDate) => {        
        state.endDate = endDate;
    },
    [actionList.REPORTCOMPAREPERIOD_STARTDATE2]: (state, startDate2) => {        
        state.startDate2 = startDate2;
    },
    [actionList.REPORTCOMPAREPERIOD_ENDDATE2]: (state, endDate2) => {        
        state.endDate2 = endDate2;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};