let settings = [
    {
        name: 'groups',
        title: 'groups',
        params: [],
        seqno: 1
    },
    {
        name: 'users',
        title: 'users',
        params: [],
        seqno: 2
    },
    {
        name: 'languages',
        title: 'languages',
        params: [],
        seqno: 3
    },
    {
        name: 'settingsExact',
        title: 'exact',
        params: [],
        seqno: 4
    }];
let companies = [];
let reports = [];
let timeregistration = [{
    name: 'crmtimeregistration',
    title: 'timeregistration',
    params: [],
    seqno: 1,
    role: 'customer',
    count: false,
    isTimeRegistration: true
},
{
    name: 'crmusertime',
    title: 'usertime',
    params: [],
    seqno: 2,
    role: 'administrator',
    count: false
},

{
    name: 'crmdeclareproposal',
    title: 'declareproposal',
    params: [],
    seqno: 3,
    role: 'customer',
    count: true
}
];
let invoicing = [{
    name: 'crmconcept',
    title: 'concepts',
    params: [],
    seqno: 1,
    role: 'administrator',
    count: false
},
{
    name: 'crmsendto',
    title: 'sendto',
    params: [],
    seqno: 2,
    role: 'administrator',
    count: false
},
{
    name: 'crmsent',
    title: 'sent',
    params: [],
    seqno: 2,
    role: 'administrator',
    count: false
}
];

export default {
    settings: settings,
    companies: companies,
    reports: reports,
    timeregistration: timeregistration,
    invoicing: invoicing
}
