import navmenu from '../navmenu/navmenu.vue';
import { AUTH_LOGOUT, SET_MODULE } from '../../../store/actions/authactions';
import { mapActions, mapGetters } from 'vuex';
//import { SET_MODULE } from '../../../store/actions/menumoduleactions';
import axios from 'axios';
import toastr from 'toastr';

export default {
    components: {
        navmenu
    },
    name: 'App',
    computed: {
        isAuthenticated() {
            return this.$store.getters['auth/isAuthenticated'];
        },
        isTestEnvironment() {
            return this.testenvironment === "True";
        },
        ...mapGetters('auth',
            ['username',
                'testenvironment',
                'isCRM',
                'isCompliance',
                'isAdvice',
                'isManagement',
                'isAdministrator',
                'currentmodule']),

    },
    methods: {
        logout: function () {
            this.doLogout()
                .then(() => {
                    this.$router.push({ name: 'login' });
                });
        },
        goToModule: function (val) {
            this.updateModule(val);            
            this.setModule(val);            
            this.$router.push({ name: 'home' });
        },
        updateModule(val) {
            axios.post(APIURL + '/user/setmodule?modulename=' + val, "")
                .then((resp) => {
                    
                })
                .catch(err => toastr.error(this.$t('error.saveChanges'), err));
        },
        ...mapActions('auth', {
            doLogout: AUTH_LOGOUT
        }),
        ...mapActions('auth', {
            setModule: SET_MODULE
        }),
    }
};