import * as actionList from '../actions/reportmissingrateactions';
import moment from 'moment';

const state = {
    search: '',
    page: 0,
    year: moment().year(),
    month: moment().month()
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    year: state => state.year,
    month: state => state.month,
};

const actions = {

    [actionList.REPORTMISSINGRATE_SEARCH]: ({ commit }, search) => {
        commit(actionList.REPORTMISSINGRATE_SEARCH, search);
    },
    [actionList.REPORTMISSINGRATE_PAGE]: ({ commit }, page) => {
        commit(actionList.REPORTMISSINGRATE_PAGE, page);
    },
    [actionList.REPORTMISSINGRATE_MONTH]: ({ commit }, month) => {
        commit(actionList.REPORTMISSINGRATE_MONTH, month);
    },
    [actionList.REPORTMISSINGRATE_YEAR]: ({ commit }, year) => {
        commit(actionList.REPORTMISSINGRATE_YEAR, year);
    },
};

const mutations = {
    [actionList.REPORTMISSINGRATE_SEARCH]: (state, search) => {
        state.search = search;
    },
    [actionList.REPORTMISSINGRATE_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionList.REPORTMISSINGRATE_MONTH]: (state, month) => {
        state.month = month;
    },
    [actionList.REPORTMISSINGRATE_YEAR]: (state, year) => {
        state.year = year;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};