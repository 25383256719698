import * as actionList from '../actions/sentlistactions';
import moment from 'moment';

const state = { 
    search:'', 
    page:0, 
    startDate: moment().startOf('year').toDate(), 
    endDate: moment().endOf('year').toDate(),    
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startDate: state => state.startDate,
    endDate: state => state.endDate,   
};

const actions = {
    [actionList.SENTLIST_SEARCH]: ({ commit }, search) => {
        commit(actionList.SENTLIST_SEARCH, search);
    },
    [actionList.SENTLIST_PAGE]: ({ commit }, page) => {
        commit(actionList.SENTLIST_PAGE, page);
    },
    [actionList.SENTLIST_STARTDATE]: ({ commit }, startDate) => {
        commit(actionList.SENTLIST_STARTDATE, startDate);
    },
    [actionList.SENTLIST_ENDDATE]: ({ commit }, endDate) => {
        commit(actionList.SENTLIST_ENDDATE, endDate);
    },    
};

const mutations = {
    [actionList.SENTLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionList.SENTLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionList.SENTLIST_STARTDATE]: (state, startDate) => {
        state.page = 0;
        state.startDate = startDate;
    },
    [actionList.SENTLIST_ENDDATE]: (state, endDate) => {
        state.page = 0;
        state.endDate = endDate;
    },    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};