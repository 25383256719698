import { SET_MODULE } from '../actions/menumoduleactions';

const state = {
    currentmodule: localStorage.getItem('current-module') || '',    
};
const getters = {
    currentmodule: state => state.currentmodule,
};
const actions = {
    [SET_MODULE]: ({ commit }, modulename) => {
        commit(SET_MODULE, modulename);
    }
};
const mutations = {
    [SET_MODULE]: (state, resp) => {        
        state.currentmodule = resp;
        localStorage.setItem('current-module', resp);        
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    
};