import * as actionList from '../actions/reportpermitsactions';
import moment from 'moment';

const state = { 
    search:'', 
    page:0, 
    startDate: moment().startOf('month').toDate(), 
    endDate: moment().endOf('month').toDate(), 
    managerId: -1,
    threepsupplier: null  
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    managerId: state => state.managerId,
    threepsupplier: state => state.threepsupplier
};

const actions = {
    [actionList.REPORTPERMITS_SEARCH]: ({ commit }, search) => {
        commit(actionList.REPORTPERMITS_SEARCH, search);
    },
    [actionList.REPORTPERMITS_PAGE]: ({ commit }, page) => {
        commit(actionList.REPORTPERMITS_PAGE, page);
    },
    [actionList.REPORTPERMITS_STARTDATE]: ({ commit }, startDate) => {
        commit(actionList.REPORTPERMITS_STARTDATE, startDate);
    },
    [actionList.REPORTPERMITS_ENDDATE]: ({ commit }, endDate) => {
        commit(actionList.REPORTPERMITS_ENDDATE, endDate);
    },
    [actionList.REPORTPERMITS_MANAGER]: ({ commit }, managerId) => {
        commit(actionList.REPORTPERMITS_MANAGER, managerId);
    },
    [actionList.REPORTPERMITS_THREEPSUPPLIER]: ({ commit }, threepsupplier) => {
        commit(actionList.REPORTPERMITS_THREEPSUPPLIER, threepsupplier);
    }
};

const mutations = {
    [actionList.REPORTPERMITS_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionList.REPORTPERMITS_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionList.REPORTPERMITS_STARTDATE]: (state, startDate) => {
        state.page = 0;
        state.startDate = startDate;
    },
    [actionList.REPORTPERMITS_ENDDATE]: (state, endDate) => {
        state.page = 0;
        state.endDate = endDate;
    },
    [actionList.REPORTPERMITS_MANAGER]: (state, managerId) => {
        state.page = 0;
        state.managerId = managerId;
    },
    [actionList.REPORTPERMITS_THREEPSUPPLIER]: (state, threepsupplier) => {
        state.page = 0;
        state.threepsupplier = threepsupplier;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};