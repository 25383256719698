import * as actionList from '../actions/reportregisteredtimeactions';
import moment from 'moment';

const state = {
    search: '',
    page: 0,
    year: moment().year(),
    userid: 0,
    worktype: 0,
    company: null
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    year: state => state.year,
    userid: state => state.userid,
    worktype: state => state.worktype,
    company: state => state.company
};

const actions = {
    [actionList.REPORTREGISTEREDTIME_SEARCH]: ({ commit }, search) => {
        commit(actionList.REPORTREGISTEREDTIME_SEARCH, search);
    },
    [actionList.REPORTREGISTEREDTIME_PAGE]: ({ commit }, page) => {
        commit(actionList.REPORTREGISTEREDTIME_PAGE, page);
    },
    [actionList.REPORTREGISTEREDTIME_YEAR]: ({ commit }, year) => {
        commit(actionList.REPORTREGISTEREDTIME_YEAR, year);
    },
    [actionList.REPORTREGISTEREDTIME_USERID]: ({ commit }, userid) => {
        commit(actionList.REPORTREGISTEREDTIME_USERID, userid);
    },
    [actionList.REPORTREGISTEREDTIME_WORKTYPE]: ({ commit }, worktype) => {
        commit(actionList.REPORTREGISTEREDTIME_WORKTYPE, worktype);
    },
    [actionList.REPORTREGISTEREDTIME_COMPANY]: ({ commit }, company) => {
        commit(actionList.REPORTREGISTEREDTIME_COMPANY, company);
    },
};

const mutations = {
    [actionList.REPORTREGISTEREDTIME_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionList.REPORTREGISTEREDTIME_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionList.REPORTREGISTEREDTIME_YEAR]: (state, year) => {
        state.page = 0;
        state.year = year;
    },
    [actionList.REPORTREGISTEREDTIME_USERID]: (state, userid) => {
        state.page = 0;
        state.userid = userid;
    },
    [actionList.REPORTREGISTEREDTIME_WORKTYPE]: (state, worktype) => {
        state.page = 0;
        state.worktype = worktype;
    },
    [actionList.REPORTREGISTEREDTIME_COMPANY]: (state, company) => {
        state.page = 0;
        state.company = company;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};