var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group", class: _vm.groupclass }, [
    _c(
      "label",
      {
        staticClass: "form-control-label",
        class: _vm.labelclass,
        attrs: { for: _vm.label }
      },
      [_vm._v(_vm._s(_vm.label) + ":")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mg-t-10 mg-sm-t-0" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }