import { COMPANYCORRESPONDENCELIST_SEARCH, COMPANYCORRESPONDENCELIST_PAGE, COMPANYCORRESPONDENCELIST_YEAR } from '../actions/companycorrespondencelistactions';
import moment from 'moment';

const state = { search:'', page:0, year: moment().year() };

const getters = {
    page: state => state.page,
    search: state => state.search,
    year: state => state.year
};

const actions = {
    [COMPANYCORRESPONDENCELIST_SEARCH]: ({ commit }, search) => {
        commit(COMPANYCORRESPONDENCELIST_SEARCH, search);
    },
    [COMPANYCORRESPONDENCELIST_PAGE]: ({ commit }, page) => {
        commit(COMPANYCORRESPONDENCELIST_PAGE, page);
    },
    [COMPANYCORRESPONDENCELIST_YEAR]: ({ commit }, year) => {
        commit(COMPANYCORRESPONDENCELIST_YEAR, year);
    }
};

const mutations = {
    [COMPANYCORRESPONDENCELIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [COMPANYCORRESPONDENCELIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [COMPANYCORRESPONDENCELIST_YEAR]: (state, year) => {
        state.year = year;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};