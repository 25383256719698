import * as actionList from '../actions/companytaxoverviewlistactions';
import moment from 'moment';

const state = {
    year: moment().year(),
    taxTypeId: null,
    periodId: null
};

const getters = {
    countryId: state => state.countryId,
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    year: state => state.year,
    taxTypeId: state => state.taxTypeId,
    periodId: state => state.periodId
};

const actions = {
    [actionList.COMPANYTAXOVERVIEWLIST_YEAR]: ({ commit }, year) => {
        commit(actionList.COMPANYTAXOVERVIEWLIST_YEAR, year);
    },
    [actionList.COMPANYTAXOVERVIEWLIST_TAXTYPEID]: ({ commit }, taxTypeId) => {
        commit(actionList.COMPANYTAXOVERVIEWLIST_TAXTYPEID, taxTypeId);
    },    
    [actionList.COMPANYTAXOVERVIEWLIST_PERIODID]: ({ commit }, periodId) => {
        commit(actionList.COMPANYTAXOVERVIEWLIST_PERIODID, periodId);
    },           
};

const mutations = {
    [actionList.COMPANYTAXOVERVIEWLIST_YEAR]: (state, year) => {
        state.year = year;
    },
    [actionList.COMPANYTAXOVERVIEWLIST_TAXTYPEID]: (state, taxTypeId) => {
        state.taxTypeId = taxTypeId;
        state.periodId = null;
    },   
    [actionList.COMPANYTAXOVERVIEWLIST_PERIODID]: (state, periodId) => {
        state.periodId = periodId;
    },             
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};