import * as actionList from '../actions/companyreminderloglistactions';
import moment from 'moment';

const state = {
    search: '', page: 0,
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
    userId: -1,
    countryId: null,
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    userId: state => state.userId,
    countryId: state => state.countryId,
};

const actions = {
    [actionList.COMPANYREMINDERLOGLIST_SEARCH]: ({ commit }, search) => {
        commit(actionList.COMPANYREMINDERLOGLIST_SEARCH, search);
    },
    [actionList.COMPANYREMINDERLOGLIST_PAGE]: ({ commit }, page) => {
        commit(actionList.COMPANYREMINDERLOGLIST_PAGE, page);
    },
    [actionList.COMPANYREMINDERLOGLIST_STARTDATE]: ({ commit }, startDate) => {
        commit(actionList.COMPANYREMINDERLOGLIST_STARTDATE, startDate);
    },
    [actionList.COMPANYREMINDERLOGLIST_ENDDATE]: ({ commit }, endDate) => {
        commit(actionList.COMPANYREMINDERLOGLIST_ENDDATE, endDate);
    },
    [actionList.COMPANYREMINDERLOGLIST_USER]: ({ commit }, userId) => {
        commit(actionList.COMPANYREMINDERLOGLIST_USER, userId);
    },
    [actionList.COMPANYREMINDERLOGLIST_COUNTRY]: ({ commit }, countryId) => {
        commit(actionList.COMPANYREMINDERLOGLIST_COUNTRY, countryId);
    },
};

const mutations = {
    [actionList.COMPANYREMINDERLOGLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionList.COMPANYREMINDERLOGLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionList.COMPANYREMINDERLOGLIST_STARTDATE]: (state, startDate) => {
        state.page = 0;
        state.startDate = startDate;
    },
    [actionList.COMPANYREMINDERLOGLIST_ENDDATE]: (state, endDate) => {
        state.page = 0;
        state.endDate = endDate;
    },
    [actionList.COMPANYREMINDERLOGLIST_USER]: (state, userId) => {
        state.page = 0;
        state.userId = userId;
    },
    [actionList.COMPANYREMINDERLOGLIST_COUNTRY]: (state, countryId) => {
        state.page = 0;
        state.countryId = countryId;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};