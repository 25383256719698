import axios from 'axios';
import Select2 from 'v-select2-component';

export default {
    components: {
        'select2': Select2
    },
    name: 'SelectCurrency',
    props: {
        value: [Number, String],
        inputclass: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            initialized: false,
            currencies:[]
        };
    },
    computed: {
        options() {
            return this.currencies.map(x => {
                return {id:x.id, text: `${x.description}`};
            });
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            axios.get(APIURL + '/compliance/settings/currency/list')
                .then(resp => {
                    this.currencies=resp.data;
                    this.initialized = true;
                });
        }
    }
};