import { COMPANYDECLAREPROPOSALLIST_SEARCH, COMPANYDECLAREPROPOSALLIST_PAGE, COMPANYDECLAREPROPOSALLIST_SORTBY } from '../actions/companydeclareproposallistactions';

const state = { search: '', page: 0, sortBy: 'name' };

const getters = {
    page: state => state.page,
    search: state => state.search,
    sortBy: state => state.sortBy
};

const actions = {
    [COMPANYDECLAREPROPOSALLIST_SEARCH]: ({ commit }, search) => {
        commit(COMPANYDECLAREPROPOSALLIST_SEARCH, search);
    },
    [COMPANYDECLAREPROPOSALLIST_PAGE]: ({ commit }, page) => {
        commit(COMPANYDECLAREPROPOSALLIST_PAGE, page);
    },
    [COMPANYDECLAREPROPOSALLIST_SORTBY]: ({ commit }, sortBy) => {
        commit(COMPANYDECLAREPROPOSALLIST_SORTBY, sortBy);
    }
};

const mutations = {
    [COMPANYDECLAREPROPOSALLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [COMPANYDECLAREPROPOSALLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [COMPANYDECLAREPROPOSALLIST_SORTBY]: (state, sortBy) => {
        state.sortBy = sortBy;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};