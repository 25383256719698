import axios from 'axios';
import Select2 from 'v-select2-component';

export default {
    components: {
        'select2': Select2
    },
    name: 'SelectCountry',
    props: {
        value: [Number,String],
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type:String,
            default: null
        },
        includeInactive: {
            type:Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        validatation: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            list:[]
        };
    },
    computed: {
        options() {
            return [
                {
                    text: this.$t('insideEU'),
                    children: this.list.filter(x => x.isEu == 1).map(x => {
                        return {id:x.id, text: x.name, code:x.alpha2};
                    })
                },
                {
                    text: this.$t('outsideEU'),
                    children: this.list.filter(x => x.isEu == 0).map(x => {
                        return {id:x.id, text: x.name, code:x.alpha2};
                    })
                },
            ];
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            axios.get(APIURL + '/compliance/settings/country/list?includeInactive='+this.includeInactive)
                .then(resp => this.list=resp.data);
        },
    }
};